/**
 * Returns the item in the array at the specified index, or null if the index is out of bounds.
 *
 * @param {Array} v - The array to retrieve the item from.
 * @param {number} idx - The index of the item to retrieve.
 * @returns {*} The item in the array at the specified index, or null if the index is out of bounds.
 */
import { IMAGE_FORMATS, VIDEO_FORMATS } from "@/constants";

export const cid = (v, idx) => {
  if (v === undefined || v === null) {
    return null;
  }
  return v[idx] !== undefined ? v[idx] : null;
};

export const formatTime = (seconds) => {
  const roundedSeconds = Math.floor(seconds);
  const hours = Math.floor(roundedSeconds / 3600);
  const remainingSecondsAfterHours = roundedSeconds % 3600;
  const minutes = Math.floor(remainingSecondsAfterHours / 60);
  const remainingSeconds = remainingSecondsAfterHours % 60;
  const milliseconds = Math.floor((seconds - roundedSeconds) * 100);

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
  const formattedMilliseconds =
    milliseconds < 10 ? "0" + milliseconds : milliseconds;
  if (seconds < 3600) {
    return (
      formattedMinutes + ":" + formattedSeconds + "." + formattedMilliseconds
    );
  } else {
    return (
      formattedHours +
      ":" +
      formattedMinutes +
      ":" +
      formattedSeconds +
      "." +
      formattedMilliseconds
    );
  }
};

export const toCamelCase = (str) => {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );
};

export const convertObjectKeysToCamelCase = (obj) => {
  const newObj = {};
  for (const key in obj) {
    newObj[toCamelCase(key)] = obj[key];
  }
  return newObj;
};

export const getExtension = (urlOrFilename) => {
  if (!urlOrFilename) {
    return null; // or return a default value as needed
  }
  const filename = urlOrFilename.split("/").pop();
  const extension = "." + filename.split(".").pop();
  return extension;
};

/**
 * Returns the type of file based on the extension
 * @param filename
 */
export const isImageOrVideo = (filename) => {
  const ext = getExtension(filename);
  console.log("fileextension", ext);
  if (IMAGE_FORMATS.includes(ext)) {
    return "image";
  } else if (VIDEO_FORMATS.includes(ext)) {
    return "video";
  }
};

export async function onProcessVideo(filepath, projectId, subjectId, queue) {
  // Hit URL to start processing the video
  const response = fetch(
      import.meta.env.VITE_VIDEO_PROCESSING_URL,
    {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceS3Key: filepath,
        project_id: projectId,
        subject_id: subjectId,
        queue: queue,
      }),
    }
  );
  console.log(await response);

  console.log("process video");
}


export const drawTimeline = (
  ctx,
  seconds,
  marginBottom,
  width,
  height,
  totalTimeHints,
  textColor="white"// new parameter for text color
) => {
  const secondsWidth = width / seconds;
  const sep = roundToThin(seconds / totalTimeHints);
  const originalColor = ctx.value.fillStyle; // save original color
  for (let i = 0; i <= seconds - 1; i++) {
    if (i % Math.floor(sep) !== 0) {
      continue;
    }
    const x = i * secondsWidth;
    ctx.value.beginPath();
    ctx.value.moveTo(x, height - marginBottom);
    ctx.value.lineTo(x, height - marginBottom + 5); // move the line-up by 10 pixels
    ctx.value.stroke();
    const text = formatTime(i).toString();
    const textWidth = ctx.value.measureText(text).width;
    const textX = x - textWidth / 2; // center text horizontally on the line
    const textY = height - 10; // set the text at the bottom of the canvas
    ctx.value.fillStyle = textColor; // set new text color
    ctx.value.fillText(text, textX, textY); // move the text up by 5 pixels
    ctx.value.fillStyle = originalColor; // set back to original color
  }
  function roundToThin(value) {
    // console.log("roundthing", value);
    const thinValues = [3, 20, 30, 50, 60];

    let minDiff = Infinity;
    let closestThin = value;

    for (let i = 0; i < thinValues.length; i++) {
      const diff = Math.abs(thinValues[i] - value);
      if (diff < minDiff) {
        minDiff = diff;
        closestThin = thinValues[i];
      }
    }

    return closestThin;
  }
};


export const calibrationScore = (responses) => {
  // Check if null
  if (responses === null) {
    return false;
  }
  let goodQuestionsSum = 0;
  let badQuestionsSum = 0;

  // Calculate sum for good and bad questions separately
  for (let i = 1; i <= 9; i++) {
    goodQuestionsSum += responses[i];
  }
  for (let i = 10; i <= 18; i++) {
    badQuestionsSum += responses[i];
  }

  // Calculate averages
  const goodQuestionsAvg = goodQuestionsSum / 9;
  const badQuestionsAvg = badQuestionsSum / 9;

  // Check conditions
  if (
    goodQuestionsAvg >= 1 &&
    goodQuestionsAvg <= 1.5 &&
    badQuestionsAvg >= 4.5 &&
    badQuestionsAvg <= 5
  ) {
    return false;
  } else {
    return true;
    // console.log("Good questions average: " + goodQuestionsAvg.toFixed(2));
    // console.log("Bad questions average: " + badQuestionsAvg.toFixed(2));
  }
};
