import { gql } from "@apollo/client/core";

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      displayName
      email
      emailVerified
      updatedAt
      avatarUrl
      disabled
    }
  }
`;

export const UPDATE_USER_DISABLED = gql`
  mutation UpdateUserDisabled($disabled: Boolean = false, $id: uuid = "") {
    updateUser(pk_columns: { id: $id }, _set: { disabled: $disabled })
  }
`;


export const UPDATE_USER_NAME = gql`
mutation updateUsers($name: String!) {
  updateUsers(where: {}, _set: { displayName: $name }) {
    affected_rows
  }
}
`;
