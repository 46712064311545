// Subject Rules

export const nameRules = [
  (v) => !!v || "El nombre es requerido",
  (v) => (v && v.length >= 3) || "El nombre debe ser mayor a 3 caracteres",
  (v) => (v && v.length <= 20) || "El nombre debe ser menor a 20 caracteres",
];

export const lastNameRules = [
  (v) => !!v || "El Apellido es requerido",
  (v) => (v && v.length >= 3) || "El nombre debe ser mayor a 3 caracteres",
  (v) => (v && v.length <= 20) || "El nombre debe ser menor a 20 caracteres",
];

export const descriptionRules = [
  (v) => !!v || "El Apellido es requerido",
  (v) => (v && v.length >= 3) || "El nombre debe ser mayor a 3 caracteres",
  (v) => (v && v.length <= 40) || "El nombre debe ser menor a 20 caracteres",
];
export const genderRules = [(v) => !!v || "El sexo es requerido"];

// Project Rules
export const projectNamesRules = [
  (v) => !!v || "El nombre es requerido",
  (v) => (v && v.length >= 3) || "El nombre debe ser mayor a 3 caracteres",
  (v) => (v && v.length <= 40) || "El nombre debe ser menor a 20 caracteres",
];

export const projectDescriptionRules = [
  (v) => !!v || "La descripción es requerida",
  (v) => (v && v.length >= 3) || "La descripción debe ser mayor a 3 caracteres",
  (v) =>
    (v && v.length <= 40) || "La descripción debe ser menor a 20 caracteres",
];

export const projectTypeRules = [(v) => !!v || "El tipo es requerido"];
