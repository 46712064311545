<!--Ejemplo de dropzone y react-->
<!--https://github.com/nhost/nhost/blob/main/examples/react-apollo/src/Storage.tsx-->
<!--Ejemplo de dropzone con vue3-->
<!--https://stackblitz.com/edit/vitejs-vite-y6moyi?file=src%2Fcomponents%2FFileUpload.vue-->
<!--https://github.com/nhost/nhost/blob/main/examples/vue-quickstart/src/pages/profile.vue-->
<template>
  <v-row>
    <v-col cols="12">
      <div v-if="isFirstSubscriptDataReceived">
        <v-row class="justify-center" style="background-color: #232323">
          <v-col
            cols="5"
            v-if="subjectSubscription?.subjects_groups?.projects?.type === 2"
            class="d-flex justify-center align-center"
            style="padding-right: 0; border-right: 1px solid #222"
          >
            <media-drop-zone
              ref="stimulusPlayer"
              :filepath="
                subjectSubscription?.subjects_groups?.projects?.stimulus_url
              "
              :width="640"
              :height="360"
              :buttonCaption="$t('video.project_button')"
              :id="subjectSubscription?.subjects_groups?.projects?.id"
              @file-uploaded="onStimulusFileUploaded"
              @error="onError"
            />
          </v-col>
          <v-col
            cols="5"
            style="padding-left: 0"
            class="d-flex justify-center align-center"
          >
            <media-drop-zone
              :controls="true"
              ref="userPlayer"
              :filepath="subjectSubscription?.video_url"
              :width="640"
              :height="360"
              :buttonCaption="$t('video.subject_button')"
              :id="subjectSubscription?.subjects_groups?.projects?.id"
              :face-position="subjectSubscription?.face_position"
              :show-upload-button="isAuth"
              @file-uploaded="onSubjectFileUploaded"
              @mounted="onMountedVideoUser"
              @seeking="onSeekingUserVideo"
              @playing="OnChangePlayingVideoStimulus"
              @timeupdate="onSeekingUserVideo"
              @pause="OnPauseUserVideo"
              @error="onError"
            />
          </v-col>
          <v-col v-if="isAuth" cols="1" class="d-flex">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn
                  icon="mdi-dots-vertical"
                  variant="tonal"
                  rounded
                  v-bind="props"
                ></v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in itemsVisibility"
                  :key="index"
                  :value="index"
                  @click="handleMenuItemClick(item)"
                >
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              <v-dialog
                v-model="showDialogRemoveSubjectEmotionData"
                width="40%"
              >
                <v-card>
                  <v-card-title>{{ $t("video.menu_item") }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      variant="tonal"
                      rounded
                      @click="onClickDialogRemoveSubjectEmotionData"
                      >Aceptar
                    </v-btn>
                    <v-btn
                      variant="tonal"
                      rounded
                      @click="emit(`update:visible`, false)"
                      >{{ $t("video.menu_item_cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-menu>
          </v-col>
        </v-row>

        <v-row
          v-if="subjectSubscription?.face_emotions"
          style="height: 48vh"
          class="overflow-x-auto"
        >
          <v-col class="pa-0">

            <VideoNotes
              v-if="subjectSubscription?.face_emotions"
              :seconds="subjectSubscription?.face_emotions[0].length"
              @update="onUpdateNotes"
              :regions="subjectSubscription?.notes"
            />
            <RowsEmotionCharts
              :time="time"
              :colors="EMOTIONS_COLORS"
              :show-grid-lines="true"
              :emotions="[
                ...subjectSubscription?.face_emotions,
                ...subjectSubscription?.voice_emotions,
              ]"
              mode="interactive"
              :labels="[...EMOTIONS_FACE[locale], ...EMOTIONS_VOICE[locale]]"
              backgroundColor="#232323"
              @playhead:move="onPlayheadMove"
              @playhead:click="onPlayheadClick"
              :height="100"
            />
          </v-col>
        </v-row>
        <v-row
          class="justify-center align-center"
          v-else-if="
            isAuth &&
            (subjectSubscription?.source_video_url ||
              subjectSubscription?.video_url ||
              formatVideoUrl(videoPublicUrl))
          "
        >
          <v-col cols="3">
            <div v-if="videoUrl">
              <div style="margin-top: 50px">
                <TimedProgressBar
                  :progress-value="subjectSubscription?.progress_processing"
                  :timer-duration="1000 * 90"
                />
              </div>
            </div>
            <!--          <div v-else>No se han procesado datos para este sujeto</div>-->
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <user-subscription
            :key="userSubscriptionId"
            :id="props.id"
            @update:subject="onSubscriptionData"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-snackbar v-model="snackbar">
      {{ snackText }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          {{ $t("video.snackbar_close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script setup>
import "video.js/dist/video-js.css";
import { computed, onMounted, ref, watch } from "vue";
import RowsEmotionCharts from "@/components/RowsEmotionCharts.vue";
import { useNhostClient } from "@nhost/vue";
import { useMutation } from "@vue/apollo-composable";

import { EMOTIONS_COLORS } from "@/constants";
import UserSubscription from "@/components/UserSubscription.vue";
import {
  REMOVE_SUBJECT_VIDEO,
  UPDATE_SUBJECT_NOTES,
  UPDATE_SUBJECT_SHARE_STATUS,
  UPDATE_SUBJECT_VIDEO_URL,
} from "@/queries/subjects";
import {
  REMOVE_PROJECT_MEDIA,
  UPDATE_PROJECT_MEDIA_URL,
} from "@/queries/projects";
import { useRoute } from "vue-router";
import { onProcessVideo } from "@/helpers";
import MediaDropZone from "@/components/MediaDropZone.vue";
import TimedProgressBar from "@/components/TimedProgressBar.vue";
import { useI18n } from "vue-i18n";
import { EMOTIONS_FACE, EMOTIONS_VOICE } from "@/i18n";
import VideoNotes from "@/components/VideoNotes.vue";

const { locale } = useI18n();

const { t } = useI18n();
const isFirstSubscriptDataReceived = ref(false);

const { nhost } = useNhostClient();

let userSubscriptionId = ref(0);
const time = ref(10);
const control = ref("");
let videoPublicUrl = ref("");
let subjectSubscription = ref(null);
let showDialogRemoveSubjectEmotionData = ref(false);
let snackText = ref("");

const isAuth = computed(() => {
  return nhost.auth.isAuthenticated();
});

const route = useRoute();
const currentRouteName = computed(() => {
  const domain = import.meta.env.VITE_BASE_URL;
  return new URL(route.fullPath, domain).toString();
});

const itemsVisibility = computed(() => {
  let resultItems = [];
  if (subjectSubscription.value?.video_url)
    resultItems.push({
      id: 1,
      title: t("itemsVisibility.deleteSubjectVideo"),
    });
  if (subjectSubscription.value?.subjects_groups?.projects?.stimulus_url) {
    resultItems.push({
      id: 2,
      title: t("itemsVisibility.deleteStimulusVideo"),
    });
  }
  resultItems.push({
    id: 3,
    title: t("itemsVisibility.calibrationFormLink"),
  });
  console.log(
    "subjectSubscription.value?.is_published",
    subjectSubscription.value?.is_published
  );
  const profileStatus = subjectSubscription.value?.is_published;
  const textProfileStatus = profileStatus
    ? t("itemsVisibility.unShareSubjectProfile")
    : t("itemsVisibility.shareSubjectProfile");
  resultItems.push({
    id: 4,
    title: textProfileStatus,
  });
  return resultItems;
});

let videoUrl = computed(() => {
  if (videoPublicUrl || subjectSubscription.value?.video_url) {
    // progressBar.value = 0;
    return videoPublicUrl || subjectSubscription.value?.video_url;
  }
  return null;
});

const props = defineProps({
  id: Number,
  playerStatus: {
    type: String,
    default: "stop",
  },
});
let snackbar = ref(false);
let isPlaying = ref(false);
let stimulusPlayer = ref(null);
let userPlayer = ref(null);

/**
 * Wait for the subscription to be ready
 * @param data
 */
const onSubscriptionData = (data) => {
  isFirstSubscriptDataReceived.value = true;
  emit("onUpdatedData", data?.subjects[0]);
  subjectSubscription.value = data?.subjects[0];
};
const { mutate: removeSubjectEmotionData, onDone: onRemoveSubjectEmotionData } =
  useMutation(REMOVE_SUBJECT_VIDEO);

const { mutate: removeProjectMedia, onDone: onRemoveProjectMedia } =
  useMutation(REMOVE_PROJECT_MEDIA);

const { mutate: queryUpdateShareStatus, onDone: onUpdateShareStatus } =
  useMutation(UPDATE_SUBJECT_SHARE_STATUS);

const onClickDialogRemoveSubjectEmotionData = () => {
  removeSubjectEmotionData({
    id: props.id,
  });

  onRemoveSubjectEmotionData(() => {
    snackbar.value = true;
    snackText.value = "Se ha eliminado el video del sujeto";
  });
};
const onClickDialogRemoveProjectMedia = () => {
  console.log(subjectSubscription.value?.subjects_groups?.projects?.id);
  removeProjectMedia({
    id: subjectSubscription.value?.subjects_groups?.projects?.id,
  });
  onRemoveProjectMedia(() => {
    snackbar.value = true;
    snackText.value = "Se ha eliminado el video del proyecto";
  });
};
const handleMenuItemClick = (item) => {
  // call the function associated with the clicked item
  console.log("handle click", item);
  if (item.id === 1) {
    onClickDialogRemoveSubjectEmotionData();
    // showDialogRemoveSubjectEmotionData.value = true;
  } else if (item.id === 2) {
    onClickDialogRemoveProjectMedia();
  } else if (item.id === 3) {
    copyCalibrationUrl();
  } else if (item.id === 4) {
    updateShareStatus(subjectSubscription.value?.is_published);
  }
};
const copyCalibrationUrl = () => {
  const url = import.meta.env.VITE_BASE_URL + "calibration/" + props.id;
  console.log(url);
  navigator.clipboard.writeText(url);
  snackbar.value = true;
  snackText.value = t("toast.calibrationFormLink");
};
const updateShareStatus = (is_published) => {
  console.log("updateShareStatus", props.id, is_published);
  queryUpdateShareStatus({
    id: props.id,
    is_published: !is_published,
  });
  onUpdateShareStatus(() => {
    if (is_published === true) {
      snackbar.value = true;
      snackText.value = t("toast.unShare");
    } else {
      console.log("updateShareStatus");
      navigator.clipboard.writeText(currentRouteName.value);
      console.log("current route", currentRouteName.value);
      snackbar.value = true;
      snackText.value = t("toast.share");
    }
  });
};

const { mutate: updateSubjectVideoURL, onDone: onUpdateSubject } = useMutation(
  UPDATE_SUBJECT_VIDEO_URL
);

const { mutate: updateProjectVideoURL, onDone: onUpdateProject } = useMutation(
  UPDATE_PROJECT_MEDIA_URL
);

// Use the mutation in the component
const { mutate: updateNotes, onDone: onDoneUpdateNotes } =
  useMutation(UPDATE_SUBJECT_NOTES);
const onUpdateNotes = (notes) => {
  updateNotes({
    id: props.id,
    notes: notes,
  });
  onDoneUpdateNotes(() => {
    snackbar.value = true;
    snackText.value = "Se ha actualizado la nota";
  });
};
const formatVideoUrl = (value) => {
  if (!value) return "";
  const url = value;
  const extensionIndex = url.lastIndexOf(".");
  const urlWithoutExtension = url.slice(0, extensionIndex);
  const extension = url.slice(extensionIndex);
  const newUrl = `${urlWithoutExtension}#${extension}`;
  return newUrl;
};

// watch from change in props.id

watch(
  () => props.id,
  () => {
    userSubscriptionId.value++;
  }
);

const onStop = () => {
  console.log("onStop", userPlayer.value);
  if (userPlayer.value) {
    try {
      userPlayer.value.pause();
    } catch (error) {
      console.log(error);
    }
  }
  if (stimulusPlayer.value)
    if (stimulusPlayer.value.readyState() !== 0) {
      stimulusPlayer.value.pause(0);
    }

  // userPlayer.value.pause();
  // stimulusPlayer.value.pause();
  setPlayerStatus("pause");
};
const onPlayheadClick = (value) => {
  control.value = "timeline";
  if (isPlayerReady(stimulusPlayer))
    stimulusPlayer.value.currentTime.set(value);
  if (userPlayer.value) userPlayer.value.currentTime.set(value);
};
const onPlayheadMove = (value) => {
  console.log("onPlayheadMove", value);
  // control.value = "timeline";
  if (stimulusPlayer.value)
    if (stimulusPlayer.value.readyState())
      stimulusPlayer.value.currentTime.set(value);
  if (userPlayer.value) userPlayer.value.currentTime.set(value);
};

const onSeekingUserVideo = () => {
  const currentTime = userPlayer.value.currentTime.get();
  if (isPlayerReady(stimulusPlayer))
    stimulusPlayer.value.currentTime.set(currentTime);
  time.value = currentTime;
};

const SyncTimeVideos = () => {
  // if (userPlayer.value && stimulusPlayer.value)
  //   stimulusPlayer.value.currentTime(userPlayer.value.currentTime());
};
const OnChangePlayingVideoStimulus = () => {
  console.log("playyyy");
  // isPlaying.value = true;
  // SyncTimeVideos();
  if (isPlayerReady(stimulusPlayer)) stimulusPlayer.value.play();
  time.value = userPlayer.value.currentTime.get();
  console.log("OnChangePlayingStimulus");
};
const OnPauseUserVideo = () => {
  isPlaying.value = false;
  SyncTimeVideos();
  if (isPlayerReady(stimulusPlayer)) stimulusPlayer.value.pause();
  console.log("OnChangePauseStimulus");
};

onMounted(() => {
  isFirstSubscriptDataReceived.value = false;
  // startProgress();
});
const onMountedVideoUser = (e) => {
  userPlayer.value = e.player;
};

const onStimulusFileUploaded = async (filepath, extension, fixedFileName) => {
  updateProjectVideoURL({
    id: subjectSubscription.value.subjects_groups?.projects?.id.toString(),
    source_stimulus_url: filepath,
    media_ext: extension,
    stimulus_url: fixedFileName,
  });
  await onProcessVideo(
    filepath,
    subjectSubscription.value.subjects_groups?.projects?.id.toString(),
    null,
    "stimulus_videos"
  );
  console.log("filepath", filepath);
};

const onSubjectFileUploaded = async (filepath, extension, fixedFileName) => {
  const subjectId = subjectSubscription.value.id.toString();

  updateSubjectVideoURL({
    id: subjectId,
    source_video_url: filepath,
    video_ext: extension,
    video_url: fixedFileName,
  });
  await onProcessVideo(
    filepath,
    subjectSubscription.value.subjects_groups?.projects?.id.toString(),
    subjectId
  );

  emit("onUpdatedData", subjectSubscription.value);
  console.log("filepath", filepath);
};

// watch the route subjectId and groupdid change and stop both players
watch(
  () => [route.params.subjectId, route.params.groupId],
  ([subjectId, groupId]) => {
    console.log("watch subjectId", subjectId);
    console.log("watch groupId", groupId);

    onStop();
  }
);

const emit = defineEmits(["toggle", "onUpdatedData"]);
const isPlayerReady = (player) => {
  if (player.value) if (player.value.readyState()) return true;
  return false;
};

const onError = (error) => {
  snackbar.value = true;
  snackText.value = error;
  // console.log("error", error);
};

function setPlayerStatus(value) {
  emit("toggle", value);
}
</script>

<style scoped src="../assets/main.css">
.dropzone {
  width: 100%;
  height: 100%;
}
</style>
