import { gql } from "@apollo/client/core";

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $title: String!
    $description: String!
    $stimulusURL: String
    $type: Int!
    $userId: uuid!
  ) {
    insert_projects_one(
      object: {
        title: $title
        description: $description
        user_id: $userId
        source_stimulus_url: $stimulusURL
        type: $type
      }
    ) {
      id
      title
      description
    }
  }
`;
export const GET_PROJECTS = gql`
  query GetProject($userId: uuid!) {
    projects(
      order_by: { created_at: desc }
      where: { user_id: { _eq: $userId } }
    ) {
      id
      title
      description
      type
      created_at
      thumbnail
    }
  }
`;
export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: Int!
    $title: String!
    $description: String!
    $stimulusURL: String
    $type: Int!
  ) {
    update_projects(
      where: { id: { _eq: $id } }
      _set: {
        description: $description
        stimulus_url: $stimulusURL
        title: $title
        type: $type
      }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    delete_subjects(where: { project_id: { _eq: $id } }) {
      affected_rows
    }
    delete_groups(where: { project_id: { _eq: $id } }) {
      affected_rows
    }

    delete_projects_by_pk(id: $id) {
      id
    }
  }
`;
export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($id: Int!) {
    projects_by_pk(id: $id) {
      id
      title
      description
      type
      stimulus_url
      created_at
    }
  }
`;

export const UPDATE_PROJECT_MEDIA_URL = gql`
  mutation UpdateProjectMediaUrl(
    $id: Int!
    $stimulus_url: String = ""
    $source_stimulus_url: String = ""
  ) {
    update_projects_by_pk(
      pk_columns: { id: $id }
      _set: {
        stimulus_url: $stimulus_url
        source_stimulus_url: $source_stimulus_url
      }
    ) {
      id
    }
  }
`;

export const REMOVE_PROJECT_MEDIA = gql`
  mutation RemoveProjectMedia($id: Int!) {
    update_projects(
      where: { id: { _eq: $id } }
      _set: { stimulus_url: "", thumbnail: "" }
    ) {
      affected_rows
    }
  }
`;
