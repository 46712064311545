<script setup>
import { ref } from "vue";
import { useUserId } from "@nhost/vue";
import { useQuery } from "@vue/apollo-composable";

import HeaderApp from "@/components/HeaderApp.vue";
import DialogProject from "@/components/dialogs/ProjectsAdmin.vue";
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECT_BY_ID,
  GET_PROJECTS,
  UPDATE_PROJECT,
} from "@/queries/projects";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { projectsTypes } from "@/i18n";
const { t } = useI18n();
let showDialogProject = ref(false);
let modeDialogProject = ref("new");
let itemId = ref(-1);
let refresh = ref(0);
let itemsPerPage = ref();
const { locale } = useI18n();

const headers = [
  {
    title: t("table.name"),
    align: "start",
    key: "title",
    width: "20%",
  },
  { title: t("table.description"), key: "description", width: "30%" },
  { title: t("table.type"), key: "type" },
  { title: t("table.creation_date"), key: "created_at", width: "20%" },
  {
    title: t("table.actions"),
    key: "actions",
    width: "10%",
    sortable: false,
  },
];

const userId = useUserId();

const { loading, result, refetch } = useQuery(GET_PROJECTS, {
  userId: userId.value,
});

const changeDateFormat = (date) => {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

const router = useRouter();
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <HeaderApp />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="justify-end d-flex">
        <v-btn
          color="info"
          rounded
          @click="
            showDialogProject = true;
            modeDialogProject = `new`;
          "
        >
          {{ t("project.new_project") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          v-model:items-per-page="itemsPerPage"
          :headers="headers"
          :items="result?.projects.map(item => ({ ...item, raw: item.raw || item }))"
          item-value="name"
          class="elevation-1"
          :footer-props="{ 'fixed-footer': true }"
          :rows-per-page-items="[10, 20, 50]"
        >
          <template v-slot:item.title="{ item }">
            <RouterLink
              class="remove-link-underline"
              :to="{ name: 'project', params: { projectId: item.raw.id } }"
            >
              {{ item.raw.title }}
            </RouterLink>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ changeDateFormat(item.raw.created_at) }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ projectsTypes[locale][parseInt(item.raw.type)] }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              size="small"
              class="me-2"
              @click="
                showDialogProject = true;
                modeDialogProject = `edit`;
                itemId = item.raw.id;
                refresh++;
              "
            >
              mdi-pencil
            </v-icon>
            <v-icon
              size="small"
              @click="
                showDialogProject = true;
                modeDialogProject = `delete`;
                itemId = item.raw.id;
                refresh++;
              "
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>

        <!--        <v-pagination v-model="page" :length="6"></v-pagination>-->

        <dialog-project
          :key="refresh"
          :visible="showDialogProject"
          :mode="modeDialogProject"
          :id="userId"
          :itemId="itemId"
          :itemDefaultValue="{
            title: ``,
            description: ``,
            type: ``,
            stimulusURL: ``,
          }"
          :item-name="$t('dialog.project')"
          :getQuery="GET_PROJECT_BY_ID"
          :createQuery="CREATE_PROJECT"
          :updateQuery="UPDATE_PROJECT"
          :deleteQuery="DELETE_PROJECT"
          @update:visible="(value) => (showDialogProject = value)"
          @trigger:refetchCreate="
            (data) =>
              router.push(`/project/${data?.data?.insert_projects_one?.id}`)
          "
          @trigger:refetchUpdate="refetch()"
          @trigger:refetchDelete="refetch()"
        >
        </dialog-project>
      </v-col>
    </v-row>
  </v-container>
</template>
