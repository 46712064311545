<script setup>
import { useDropzone } from "vue3-dropzone";
import { reactive, ref, watchEffect } from "vue";
import { useFileUpload, useNhostClient } from "@nhost/vue";
import { getExtension } from "@/helpers";
import { IMAGE_FORMATS, VIDEO_FORMATS } from "@/constants";

let progressInButton = ref(false);
let fileUploaded = ref(false);

const props = defineProps({
  caption: {
    type: String,
    default: "",
  },
});

const emit = defineEmits([
  "update:publicUrl",
  "update:progress",
  "update:fileUploaded",
  "error",
]);

const state = reactive({
  files: [],
  multiple: false,
});

const { nhost } = useNhostClient();
const { upload, isUploading, isError, progress } = useFileUpload();

watchEffect(() => {
  if (progress && isUploading && isError) {
    console.log(progress.value, isUploading.value, isError.value);
  }
});

const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
  onDrop,
  accept: [...VIDEO_FORMATS, ...IMAGE_FORMATS],
});

function onDrop(acceptFiles, rejectReasons) {
  console.log("acceptFiles files", acceptFiles);
  console.log(rejectReasons);
  state.files = acceptFiles;
  onSubmitFile();
}

const onSubmitFile = async () => {
  try {
    progressInButton.value = true;
    console.log("state.files", state.files);
    const { id: documentId, isUploaded } = await upload({
      file: state.files[0],
    });

    if (isUploaded) {
      progressInButton.value = false;
      fileUploaded.value = true;
      const extension = getExtension(state.files[0].name);

      // video.js needs the extension to recognize the file type and nhost doesn't return it
      const filepath = await nhost.storage.getPublicUrl({
        fileId: documentId,
      });

      emit("update:publicUrl", filepath, extension);
    }
  } catch (error) {
    progressInButton.value = true;
    emit("error", error);
    console.log(error);
  }
};
</script>

<template>
  <div>{{ progress }}</div>
  <div class="dropzone" v-bind="getRootProps()">
    <input v-bind="getInputProps()" />
    <p v-if="isDragActive">Drop the files here ...</p>
  </div>

  <v-btn rounded variant="tonal" @click="open" :loading="progressInButton"
    >{{ props.caption }}
  </v-btn>
</template>
