<template>
  <div>
    <button @click="generatePdf">Convert to PDF</button>
    <canvas ref="pdfCanvas"></canvas>
  </div>
  <div id="app" ref="testHtml">
    <h1>Test heading</h1>
    <div class="card">
      <div class="card-header">Featured</div>
      <div class="card-body">
        <h5 class="card-title">Special title treatment</h5>
        <p class="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
    <div class="card">
      <div class="card-header">Featured</div>
      <div class="card-body">
        <h5 class="card-title">Special title treatment</h5>
        <p class="card-text">
          With supporting text below as a natural lead-in to additional content.
        </p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
    <button class="btn btn-danger" @click="generatePdf">generate PDF</button>
  </div>
</template>

<script setup>
import jsPDF from "jspdf";
import { ref } from "vue";

// const props = defineProps({
//   html: String,
// });

import html2canvas from "html2canvas";
const testHtml = ref(null);

const generatePdf = () => {
  const doc = new jsPDF("p", "pt", "A4");
  const margins = {
    top: 80,
    bottom: 60,
    left: 40,
    width: 522,
  };

  html2canvas(testHtml.value, {useCORS:true}).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    doc.addImage(
      imgData,
      "PNG",
      margins.left,
      margins.top,
      margins.width,
      0,
      null,
      "FAST"
    );
    doc.save("test.pdf");
  });
};
</script>
