<template>
  <div>{{ $t('video_processing') }}</div>
  <v-progress-linear
    striped
    :model-value="value + props.progressValue / (100 / 30)"
    :height="20"
    color="light-blue"
  >
    <template v-slot:default="{}">
      <strong>
        {{
          Math.ceil(value + props.progressValue / (100 / 30)) > 100
            ? 100
            : Math.ceil(value + props.progressValue / (100 / 30))
        }}%</strong
      >
    </template>
  </v-progress-linear>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  progressValue: { type: Number, default: -1 },
  timerDuration: { type: Number, default: 5000 },
});

const value = ref(0);
let interval;

const startProgress = (endValue) => {
  let startValue = value.value;
  let increment = ((endValue - startValue) / props.timerDuration) * 10;

  interval = setInterval(() => {
    value.value += increment;
    if (value.value >= endValue) {
      clearInterval(interval);
    }
    if (props.progressValue > 0) {
      // Stop progress timer if progressValue is positive
      clearInterval(interval);
      value.value = 70;
    }
  }, 10);
};

watch(
  () => props.progressValue,
  (newValue, oldValue) => {
    if (oldValue === -1 && newValue >= 0) {
      startProgress(70 + (newValue / 100) * 30);
    }
  }
);

startProgress(70);
</script>
