<template>
  <div v-if="emotions">
    <div
      v-for="(emotion, index) in emotions"
      :key="emotion"
      style="background: #232323"
    >
      <v-row
        class="pa-0 ma-0"
        style="border-bottom: 2px solid #313131"
        v-if="index != 6"
      >
        <v-col
          v-if="props.labels"
          cols="1"
          class="justify-center align-center d-flex"
          :style="{ backgroundColor }"
        >
          <div class="text-subtitle-2">{{ props.labels[index] }}</div>
        </v-col>
        <!--        :series="[cid(face_emotions, index), cid(voice_emotions, index)]"-->
        <v-col cols="11" class="pa-0 ma-0">
          <time-line-chart
            v-if="cid(emotion, 0) !== null"
            :series="[emotion]"
            :time="time"
            :colors="colors[index]"
            :mode="props.mode"
            :show-grid-lines="props.showGridLines"
            :height="props.height"
            :threshold="props.threshold"
            :is-step-chart="props.isStepChart"
            :background-color="props.backgroundColor"
            @update:line="updateTime"
            @playhead:move="playheadMove"
            @playhead:click="playheadClick"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import TimeLineChart from "@/components/TimeLineChart.vue";
import { ref, watch } from "vue";
import { cid } from "@/helpers";

const playheadMove = (event) => {
  emit("playhead:move", event);
};
const playheadClick = (event) => {
  emit("playhead:click", event);
};


const time = ref(10);
const updateTime = (value) => {
  time.value = value;
};

const props = defineProps({
  time: { type: Number },
  emotions: { type: Object },
  colors: { type: Array },
  labels: { type: Array },
  mode: { type: String, default: "interactive" },
  isStepChart: { type: Boolean },
  threshold: { type: Number || String || null },
  height: { type: Number || String || null },
  showGridLines: { type: Boolean },
  backgroundColor: { type: String },
});

const emit = defineEmits(["update:line", "playhead:move", "playhead:click"]);

watch(
  () => props.time,
  (newVal) => {
    time.value = newVal;
  }
);

watch(
  time,
  (newValue) => {
    emit("update:line", newValue);
  },
  { deep: true }
);
</script>

<style scoped></style>
