<template>
  <div id="videoWrapper">
    <canvas
      ref="myCanvas"
      :width="props.width"
      :height="props.height"
      id="myCanvas"
    ></canvas>
    <video ref="myVideo" id="myVideo">
      <source :src="props.src" type="video/mp4" />
    </video>
    <div v-if="buffering" class="loading">Loading...</div>

    <div
      v-if="props.controls"
      id="customControls"
      class="d-flex justify-start align-center"
    >
      <!--      <button @click="togglePlayPause" id="playPauseButton">Play</button>-->
      <v-btn
        size="small"
        variant="plain"
        @click="togglePlayPause"
        style="margin-left: 10px"
      >
        <v-icon v-if="isPlaying">mdi-pause</v-icon>
        <v-icon v-if="!isPlaying">mdi-play</v-icon>
      </v-btn>
      <v-btn size="small" @click="toggleMute" variant="plain">
        <v-icon v-if="isMuted">mdi-volume-mute</v-icon>
        <v-icon v-if="!isMuted">mdi-volume-high</v-icon>
      </v-btn>

      <div class="text-subtitle-2" id="timeDisplay">
        {{ currentPlayerTime }} / {{ duration }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";

const props = defineProps({
  controls: {
    type: Boolean,
    default: true,
  },
  src: {
    type: String,
    default: "",
  },
  loop: {
    type: Boolean,
    default: false,
  },
  volume: {
    type: Number,
    default: 1,
  },
  width: {
    type: Number,
    default: 640,
  },
  height: {
    type: Number,
    default: 360,
  },
  facePosition: {
    type: Array,
    default: () => [],
  },
});

onMounted(() => {
  emit("mounted", myVideo.value);
});
const pause = () => {
  myVideo.value.pause();
};
const play = () => {
  console.log("play video player");
  myVideo.value.play();
};

const currentTime = {
  get: () => {
    return myVideo.value.currentTime;
  },
  set: (value) => {
    myVideo.value.currentTime = value;
  },
};

const readyState = () => {
  return myVideo.value.readyState;
};
const emit = defineEmits([
  "mounted",
  "seeking",
  "playing",
  "timeupdate",
  "pause",
  "currentTime",
]);

defineExpose({ currentTime, pause, play, readyState });
const myVideo = ref(null);
const currentSrc = ref(props.src);
const myCanvas = ref(null);
const currentPlayerTime = ref("0:00");
const duration = ref("0:00");
const isPlaying = ref(false);
const isMuted = ref(false);
const buffering = ref(false);
function drawUnfilledRectangle(context, x, y, width, height, color) {
  context.strokeStyle = color;
  context.strokeRect(x, y, width, height);
}

onUnmounted(() => {
  console.log("onUnmounted");
});
onMounted(() => {
  const canvas = myCanvas.value;
  const context = canvas.getContext("2d");

  const color = ["blue", "red"]; // Change this to the desired color of the squares

  myVideo.value.addEventListener("waiting", function () {
    // buffering.value = true;
    console.log(buffering.value)

  });
  myVideo.value.addEventListener("stalled", function () {
    // buffering.value = true
    console.log(buffering.value)
  });

  myVideo.value.addEventListener("loadedmetadata", function () {
    duration.value = formatTime(myVideo.value.duration);
    isMuted.value = myVideo.value.muted;
  });

  myVideo.value.addEventListener("play", function () {
    isPlaying.value = true;
    buffering.value = false;
    console.log("playing");
    console.log(buffering.value)
    emit("playing");
  });

  myVideo.value.addEventListener("pause", function () {
    isPlaying.value = false;
    console.log("pause");
    emit("pause");
  });
  myVideo.value.addEventListener("timeupdate", () => {
    emit("seeking");
    currentPlayerTime.value = formatTime(myVideo.value.currentTime);
    duration.value = formatTime(myVideo.value.duration);
    buffering.value= false
    const currentSecond = Math.floor(myVideo.value.currentTime);
    // const currentSquares = squares[currentSecond];
    context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas before drawing the squares
    // iterate over the squares and draw them

    // console.log("00000",props.facePosition[currentSecond])
    if (props.facePosition) {
      for (let i = 1; i < props.facePosition.length; i++) {
        const [frame, x, y, width, height] = props.facePosition[currentSecond];

        // console.log(props.facePosition[i])
        drawUnfilledRectangle(context, x, y, width, height, color[i]);
      }
    }
  });
});

watch(
  () => props.volume,
  (newVolume) => {
    myVideo.value.volume = newVolume;
  }
);

const loadVideo = () => {
  if (myVideo.value) {
    myVideo.value.load();
  }
};

watch(
  () => props.src,
  (newValue) => {
    currentSrc.value = newValue;
    loadVideo();
  }
);

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
}

function togglePlayPause() {
  if (myVideo.value.paused) {
    myVideo.value.play();
  } else {
    myVideo.value.pause();
  }
}

function toggleMute() {
  if (myVideo.value.muted) {
    myVideo.value.muted = false;
    isMuted.value = false;
  } else {
    myVideo.value.muted = true;
    isMuted.value = true;
  }
}
</script>

<style scoped>
#videoWrapper {
  position: relative;
  width: 100%;
  height: 320px;
}

#myVideo {
  width: 100%;
  height: 320px;
  object-fit: cover;
}
#myCanvas {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 1;
  width: 100%;
  height: 320px;
}

#customControls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

#playPauseButton,
#volumeButton {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  margin-left: 20px;
}

video::-webkit-media-controls {
  display: none !important;
}

video {
  z-index: 0;
}

.loading{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
