<template>
  <v-dialog
    persistent
    v-model="localVisible"
    v-if="props.mode === `new` || props.mode === `edit`"
    width="40%"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-form ref="form" fast-fail @submit.prevent>
        <v-card-title
          >{{
            props.mode === `edit`
              ? $t("dialog.edit") + " " + props.itemName
              : $t("dialog.new") + " " + props.itemName
          }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newItem.title"
            density="compact"
            :label="$t('dialog.study_name')"
            required
            rounded
            :rules="projectNamesRules"
          ></v-text-field>
          <v-text-field
            v-model="newItem.description"
            density="compact"
            :label="$t('dialog.description')"
            required
            :rules="projectDescriptionRules"
          ></v-text-field>
          <v-select
            v-model="newItem.type"
            :items="
              Object.keys(projectsTypes[locale]).map((key) => ({
                value: parseInt(key),
                title: projectsTypes[locale][key],
              }))
            "
            item-text="title"
            item-value="value"
            density="compact"
            :label="$t('dialog.study_type')"
            required
            :rules="projectTypeRules"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            type="submit"
            @click="submitForm"
          >
            {{ $t("dialog.accept") }}
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="emit(`update:visible`, false)"
          >
            {{ $t("dialog.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>

  <!-- Dialog for delete -->
  <v-dialog v-if="props.mode === `delete`" v-model="localVisible" width="20%">
    <v-card>
      <v-card-title
        >{{ $t("dialog.delete_confirm") }} {{ props.itemName }}?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="blue-darken-1"
          variant="text"
          @click="OnClickDeleteItem"
        >
          {{ $t("dialog.accept") }}
        </v-btn>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="emit(`update:visible`, false)"
        >
          {{ $t("dialog.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { projectsTypes } from "@/i18n";
const { locale } = useI18n();
const closeDialog = () => {
  emit("update:visible", false);
};
import {
  projectDescriptionRules,
  projectNamesRules,
  projectTypeRules,
} from "@/validation-rules";
import { ref, watch, watchEffect } from "vue";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";

import DropZone from "@/components/DropZone.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: "new",
  },
  id: {
    type: String,
    default: null,
  },
  itemId: {
    type: Number,
    default: null,
  },
  itemDefaultValue: {
    type: Object,
    default: () => {},
  },
  getQuery: {
    default: () => {},
  },
  createQuery: {
    default: () => {},
  },
  updateQuery: {
    default: () => {},
  },
  deleteQuery: {
    default: () => {},
  },
  itemName: {
    type: String,
    default: "",
  },
});

const emit = defineEmits([
  "update:visible",
  "trigger:refetchCreate",
  "trigger:refetchUpdate",
  "trigger:refetchDelete",
]);

const localVisible = ref(props.visible);
const form = ref();
watch(
  () => props.visible,
  (newValue) => {
    localVisible.value = newValue;
  }
);

const newItem = ref(props.itemDefaultValue);

const { mutate: queryCreateItem, onDone: onCreateItemDone } = useMutation(
  props.createQuery
);

const { load: queryLoadItems, onResult: queryResultItems } = useLazyQuery(
  props.getQuery,
  { id: props.id }
);

const { mutate: queryUpdateItem, onDone: onUpdateItemDone } = useMutation(
  props.updateQuery
);

const { mutate: queryDeleteItem, onDone: onDeleteItemDone } = useMutation(
  props.deleteQuery
);

const submitForm = async () => {
  const { valid } = await form.value.validate();
  if (valid) {
    if (props.mode === `edit`) onClickUpdateItem();
    else onClickCreateItem();
  }
};
const onClickCreateItem = () => {
  // if (!newProject.value.title || !newProject.value.description)
  //   return alert("Falta llenar campos");
  console.log(newItem.value);
  const variables = { ...newItem.value, userId: props.id };

  // Ensure that all keys in itemDefaultValue have a value in the variables object
  for (const key in props.itemDefaultValue) {
    if (
      !Object.prototype.hasOwnProperty.call(variables, key) ||
      variables[key] === null
    ) {
      variables[key] = props.itemDefaultValue[key];
    }
  }
  queryCreateItem(variables);
};

const cleanForm = () => {
  newItem.value = props.itemDefaultValue;
};
onCreateItemDone((result) => {
  cleanForm();
  console.log("oncreateitemdonde", result.data);
  emit("update:visible", false);
  emit("trigger:refetchCreate", result);
});

const onClickUpdateItem = () => {
  // Update
  const variables = { ...newItem.value, id: props.itemId };

  // Ensure that all keys in itemDefaultValue have a value in the variables object
  for (const key in props.itemDefaultValue) {
    if (
      !Object.prototype.hasOwnProperty.call(variables, key) ||
      variables[key] === null
    ) {
      variables[key] = props.itemDefaultValue[key];
    }
  }

  queryUpdateItem(variables);
};
onUpdateItemDone(() => {
  cleanForm();
  emit("update:visible", false);
  emit("trigger:refetchUpdate");
});
watchEffect(() => {
  console.log(props.mode, props.itemId);
  if (props.mode === "new") {
    cleanForm();
  } else if (props.mode === "edit") {
    queryLoadItems(
      props.getQuery,
      { id: props.itemId },
      { fetchPolicy: "network-only" }
    );
  }
});

queryResultItems((result) => {
  // Get the values of the result.data object
  const dataValues = Object.values(result.data);

  // Check if dataValues is not empty and access the first value (projects_by_pk)
  const itemData = dataValues.length > 0 ? dataValues[0] : null;

  // Check if projectData is not null, then extract the values
  if (itemData) {
    // Loop through the keys of the itemDefaultValue object
    for (const key in props.itemDefaultValue) {
      // Check if the projectData object has the property
      if (Object.prototype.hasOwnProperty.call(itemData, key)) {
        // Assign the value from projectData to newItem.value
        newItem.value[key] = itemData[key];
      }
    }
  }
});

const OnClickDeleteItem = () => {
  queryDeleteItem({ id: props.itemId });
  emit("update:visible", false);
};

onDeleteItemDone(() => {
  emit("trigger:refetchDelete");
});

const onFileUploaded = (fileUrl) => {
  newItem.value.stimulusURL = fileUrl;
  console.log("file uploaded", fileUrl);
};
</script>
