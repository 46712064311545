<script setup>
import "vue3-treeview/dist/style.css";
import { computed, ref, toRef, watch, watchEffect } from "vue";
import UserInfo from "@/components/UserInfo.vue";
import { useRoute, useRouter } from "vue-router";
import { useLazyQuery, useMutation, useQuery } from "@vue/apollo-composable";
import UsersPerGroup from "@/components/UsersPerGroup.vue";
import EmotionsReport from "@/components/EmotionsReport.vue";
import {
  GET_SUBJECTS_BY_IDS,
  GET_SUBJECTS_PER_PROJECT,
} from "@/queries/subjects";
import HeaderApp from "@/components/HeaderApp.vue";
import { cid } from "@/helpers";
import { useNhostClient } from "@nhost/vue";
import UserList from "@/components/UserList.vue";
import { CREATE_GROUP } from "@/queries/group";

const route = useRoute();
const router = useRouter();

let dialogNewGroup = ref(false);
const playerStatus = ref("reset");
let headerTitle = ref("");
let headerSubtitle = ref("");
const groupId = ref();
const groupData = ref();
const timestamp = ref(0);
const props = defineProps({
  projectId: {
    type: Number,
    required: true,
  },
  groupId: {
    type: Number,
  },
  subjectId: {
    type: Number,
  },
});

const subjectIdRef = toRef(props, "subjectId");
let subjectIdInternal = computed(() => {
  return subjectIdRef.value;
});

// Group Queries
const { mutate: createGroup, onDone: onCreateGroupDone } =
  useMutation(CREATE_GROUP);

const {
  loading: loadingGroupsSubjects,
  result: resultGroupsSubjects,
  refetch: refetchGroupsSubjects,
  onResult: onResultGroupsSubjects,
} = useQuery(GET_SUBJECTS_PER_PROJECT, {
  id: props.projectId,
});

onResultGroupsSubjects((data) => {
  first_subject.value =
    data.data?.projects?.[0]?.groups?.[0]?.subjects?.[0]?.id ?? null;
  console.log(
    "first_subject",
    first_subject.value,
    router.currentRoute.value.name,
    first_subject.value &&
      (router.currentRoute.value.name === "project" ||
        router.currentRoute.value.name === "group")
  );

  if (route.params.subjectId) first_subject.value = route.params.subjectId;
  // if the router point to the project and there is a subject, go to the first subject

  if (
    first_subject.value &&
    (router.currentRoute.value.name === "project" ||
      router.currentRoute.value.name === "project-subject")
  ) {
    // use replace since this runs when the view mounts
    router.replace(
      `/project/${route.params.projectId}/subject/${parseInt(
        first_subject.value
      )}`
    );
    currentView.value.type = "subject";
    showCreateGroup.value = false;
  } else if (router.currentRoute.value.name === "project-group") {
    currentView.value.type = "group";
  } else if (router.currentRoute.value.name === "report") {
    currentView.value.type = "report";
  } else {
    showCreateGroup.value = true;
  }
});

const { nhost } = useNhostClient();

const newGroup = ref({
  name: "",
  description: "",
  projectId: "",
});

const onShowReports = (data) => {
  console.log("onShowReports", data);
  currentView.value.type = "report";
  router.push(`/report/${route.params.projectId}`);
};

const updateHeaderTitle = (title, subTitle) => {
  headerTitle.value = title;
  headerSubtitle.value = subTitle;
};
const { load: loadSubject, onResult: resultSubject } =
  useLazyQuery(GET_SUBJECTS_BY_IDS);

const resultSubjectsById = ref();

resultSubject((result) => {
  console.log("result", result);
  if (typeof result === "undefined") return;
  result = result?.data?.subjects; // Convert the result from the graphql to the format user_id:{...user_data}
  if (cid(result, 0)) resultSubjectsById.value = result[0];
});

const handleCreateGroup = () => {
  if (!newGroup.value.name || !newGroup.value.description)
    return alert("Falta llenar campos");

  createGroup({
    name: newGroup.value.name,
    description: newGroup.value.description,
    projectId: props.projectId,
  });
};

onCreateGroupDone((result) => {
  console.log("onCreateGroupDone", result?.data?.insert_groups_one?.id);
  newGroup.value = {
    name: "",
    description: "",
    projectId: "",
  };
  dialogNewGroup.value = false;
  showCreateGroup.value = false;
  router.push(
    `/project/${props.projectId}/group/${result?.data?.insert_groups_one?.id}`
  );
  refetchGroupsSubjects({ id: props.projectId });
});

watch(
  () => router.currentRoute.value.name,
  (newValue, oldValue) => {
    console.log("watch", newValue, oldValue);
    if (newValue === "report") {
      currentView.value.type = newValue;
      updateHeaderTitle("");
    } else if (newValue === "project-subject") {
      currentView.value.type = "subject";
    } else if (newValue === "project-group") {
      updateHeaderTitle("");
      currentView.value.type = "group";
    } else if (newValue === "project") {
      refetchGroupsSubjects({ id: props.projectId });
    }
  }
);
const currentView = ref({ type: "" });

const onClickCard = (e) => {
  const id = e.id;
  if (e.type === "group") {
    currentView.value.type = "group";
    groupId.value = parseInt(id);
    groupData.value = e;

    router.push(
      `/project/${route.params.projectId}/group/${
        groupId.value || props.groupId
      }`
    );
  } else {
    currentView.value.type = "subject";
    // subjectId.value = parseInt(id);
    timestamp.value += 1;
    console.log("subjectIdInternal", subjectIdInternal.value);
    router.push(`/project/${route.params.projectId}/subject/${parseInt(id)}`);
  }
  // }
  playerStatus.value = "reset";
  console.log("currentData", currentView.value.type, id);
};

const isAuth = computed(() => {
  return nhost.auth.isAuthenticated();
});
let first_subject = ref(null);
let showCreateGroup = ref(false);
let colsLeft = ref(0);
let colsRight = ref();
let showLeft = ref(false);
let showRight = ref();

watchEffect(() => {
  if ((showCreateGroup.value && isAuth.value) || !isAuth.value) {
    colsLeft.value = 0;
    colsRight.value = 12;
    showLeft.value = false;
    showRight.value = true;
  } else if (isAuth.value) {
    colsLeft.value = 3;
    colsRight.value = 9;
    showLeft.value = true;
    showRight.value = true;
  }

  console.log("showCrateGroup", showCreateGroup.value);
});
</script>
<template>
  <v-container
    fluid
    style="display: flex; flex-direction: column; min-height: 100vh"
  >
    <header-app :title="headerTitle" :sub-title="headerSubtitle">
      <template v-slot:actions v-if="currentView?.type !== 'report'">
        <v-btn color="info" rounded @click="onShowReports">
          {{ $t("report.view_report") }}
        </v-btn>
      </template>
    </header-app>

    <v-row class="fill-height">
      <v-col :cols="colsLeft" v-if="showLeft" style="background-color: #000000">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-dialog v-model="dialogNewGroup" width="40%">
                  <template v-slot:activator="{ props }">
                    <v-btn variant="tonal" rounded v-bind="props">
                      {{ $t("groups.add_group") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>{{ $t("groups.new_group") }}</v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="newGroup.name"
                        density="compact"
                        :label="$t('groups.group_name')"
                        required
                        rounded
                      ></v-text-field>
                      <v-text-field
                        v-model="newGroup.description"
                        density="compact"
                        :label="$t('groups.description')"
                        required
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        type="submit"
                        @click="handleCreateGroup"
                      >
                        {{ $t("groups.accept") }}
                      </v-btn>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        type="submit"
                        @click="dialogNewGroup = false"
                      >
                        {{ $t("groups.cancel") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <user-list
                  :group-id="props.projectId"
                  :items="resultGroupsSubjects"
                  @click-card="onClickCard"
                ></user-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="colsRight" v-if="showRight">
        <v-row>
          <v-col cols="" v-if="currentView?.type === 'subject'">
            <user-info
              :playerStatus="playerStatus"
              :id="subjectIdInternal"
              @toggle="(value) => (playerStatus = value)"
              @onUpdatedData="
                (data) => {
                  updateHeaderTitle(
                    $t('subject.subject'),
                    data.name + ' ' + data.last_name
                  );
                  refetchGroupsSubjects({ id: props.projectId });
                }
              "
            ></user-info>
          </v-col>
          <v-col v-else-if="currentView?.type === 'group'">
            <UsersPerGroup
              :groupId="groupId || route.params.groupId"
              :projectId="projectId"
              @trigger:refetch="
                () => {
                  refetchGroupsSubjects({ id: props.projectId });
                  updateHeaderTitle('', '');
                }
              "
            />
          </v-col>
          <v-col v-else-if="currentView?.type === 'report'">
            <div v-if="resultGroupsSubjects">
              <emotions-report
                :nodes="resultGroupsSubjects"
                :print="true"
              ></emotions-report>
            </div>
          </v-col>
          <v-col
            v-else-if="showCreateGroup"
            class="d-flex align-center justify-center flex-column"
          >
            <div class="text-h5" style="margin: 50px">
              {{ $t("start.create_group_to_start") }}
            </div>
            <v-dialog v-model="dialogNewGroup" width="40%">
              <template v-slot:activator="{ props }">
                <v-btn variant="tonal" rounded v-bind="props">
                  {{ $t("groups.add_group") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>{{ $t("groups.new_group") }}</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="newGroup.name"
                    density="compact"
                    :label="$t('groups.group_name')"
                    required
                    rounded
                  ></v-text-field>
                  <v-text-field
                    v-model="newGroup.description"
                    density="compact"
                    :label="$t('groups.description')"
                    required
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    type="submit"
                    @click="handleCreateGroup"
                  >
                    {{ $t("groups.accept") }}
                  </v-btn>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    type="submit"
                    @click="dialogNewGroup = false"
                  >
                    {{ $t("groups.cancel") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
