import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";

import "./assets/main.css";

import { NhostClient } from "@nhost/vue";
import { createApolloClient } from "@nhost/apollo";
import { DefaultApolloClient } from "@vue/apollo-composable";
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as labs from "vuetify/labs/components";
const app = createApp(App);
import i18n from "./i18n";
// app.use(createPinia());
// app.use(router);

const vuetify = createVuetify({
  components: {
    ...labs,
    ...components,
  },
  directives,
  theme: {
    defaultTheme: "dark",
  },
});

const nhost = new NhostClient({
  region: import.meta.env.VITE_NHOST_REGION,
  subdomain: import.meta.env.VITE_NHOST_SUBDOMAIN,
});
const apolloClient = createApolloClient({
  nhost,
});

router.beforeEach(async (to) => {
  console.log("to", to);
  const authenticated = await nhost.auth.isAuthenticatedAsync();
  // Give access to the public to a subject info
  if (to.name === "project-subject") {
    return true;
  }

  if (
    !authenticated &&
    to.path !== "/login" &&
    to.path !== "/forgot-password" &&
    to.path !== "change-password" &&
    to.path !== "/admin/users"
  ) {
    return "/login";
  }
  return true;
});

app
  .use(nhost)
  .use(router)
  .use(i18n)
  .use(vuetify)
  .provide(DefaultApolloClient, apolloClient)
  .mount("#app");
