<template>
  <div v-if="fileType === 'video'">
    <video-player-i
      :controls="props.controls"
      ref="player"
      :src="props.filepath"
      :volume="0.6"
      :width="640"
      :height="360"
      :face-position="props.facePosition"
      @playing="emit('playing')"
      @pause="emit('pause')"
      @seeking="emit('seeking')"
    />
  </div>
  <div v-else-if="fileType === 'image'" class="d-flex">
    <img
      :src="props.filepath"
      width="640"
      height="360"
      alt="Imagen de Estimulo"
    />
  </div>
  <div v-else>
    <v-col class="d-flex justify-center align-center">
      <drop-zone v-if="props.showUploadButton"
        :caption="props.buttonCaption"
        @update:publicUrl="onFileUploaded"
        @error="(error) => emit('error', error)"
      />
    </v-col>
  </div>
</template>

<script setup>
import DropZone from "@/components/DropZone.vue";

import { computed, ref } from "vue";
import { isImageOrVideo } from "@/helpers";
import VideoPlayerI from "@/components/VideoPlayerI.vue";

const onFileUploaded = async (filepath, extension) => {
  const fixedFileName = filepath + "#" + extension;
  emit("fileUploaded", filepath, extension, fixedFileName);
};

const emit = defineEmits([
  "playing",
  "fileUploaded",
  "process",
  "seeking",
  "pause",
  "error",
]);
const props = defineProps({
  buttonCaption: {
    type: String,
    default: "",
  },
  filepath: {
    type: String,
    default: "",
  },
  width: {
    type: Number,
    default: 640,
  },
  height: {
    type: Number,
    default: 360,
  },
  /** Used to info when upload */
  id: {
    type: Number,
  },
  facePosition: {
    type: Array,
    default: () => [],
  },
  controls: {
    type: Boolean,
    default: false,
  },
  showUploadButton: {
    type: Boolean,
    default: true,
  },
});

const player = ref(null);

let fileType = computed(() => {
  return isImageOrVideo(props.filepath);
});

// const onMountedVideo = (myVideo) => {
//   console.log("onmountedvideo", myVideo);
//   player.value = myVideo;
// };

// const currentTime = (value) => {
//   // console.log("current time", player.value.currentTime.get());
//   // console.log("currentTimeaaa", player.value);
//   player.value.currentTime.set(value);
// };

const currentTime = {
  get: () => {
    return player.value.currentTime.get();
  },
  set: (value) => {
    player.value.currentTime.set(value);
  },
};

const duration = () => {
  return player.value.duration();
};
const play = () => {
  emit("playing");
  console.log("play media player");
  player.value.play();
};
const pause = () => {
  if (player.value) player.value.pause();
};

const readyState = () => {
  if (player.value) return player.value.readyState();
  return null;
};
defineExpose({
  currentTime,
  duration,
  pause,
  play,
  readyState,
});
</script>
