import { createI18n } from "vue-i18n";

export const messages = {
  en: {
    password: {
      newPassword: "New Password",
      changeButton: "Change Password",
      changeSuccessful: "Change Password Successful",
    },
    username:{
      changeName: "Change Name",
      changeNameSuccessful: "Change Name Successful",
    },
    language:{
      title: "Language",
    },
    toast: {
      deleteSubjectVideo: "Subject video deleted",
      deleteStimulusVideo: "Stimulus video deleted",
      calibrationFormLink: "Calibration Form Link copied to clipboard",
      share: "This subject profile is now public. Url copied to clipboard",
      unShare: "This subject profile is now private",
    },
    calibration_status: "Not taking into account",
    profile: "Profile",
    logout: "Logout",
    video_processing: "Processing video...",
    notes:"Notes",
    project: {
      new_project: "New Project",
    },
    table: {
      name: "Name",
      description: "Description",
      type: "Type",
      creation_date: "Creation Date",
      actions: "Actions",
    },
    video: {
      subject_button: "Upload Subject Video",
      project_button: "Upload Image or Video for the project",
      menu_item: "Delete Video",
      menu_item_cancel: "Cancel",
      snackbar_close: "Close",
    },
    nav: {
      home: "Home",
      about: "About",
    },
    login: {
      email_label: "Email",
      password_label: "Password",
      login_button: "Login",
      forgot_password_link: "Forgot your password?",
      error_invalid_email: "Invalid email format",
      error_wrong_credentials: "Wrong email or password",
      error_close_button: "Close",
    },
    about: {
      header: "About us",
    },
    groups: {
      add_group: "Add Group",
      new_group: "New Group",
      group_name: "Group name",
      description: "Description",
      accept: "Accept",
      cancel: "Cancel",
    },
    dialog: {
      project: "Project",
      edit: "Edit",
      new: "New",
      accept: "Accept",
      cancel: "Cancel",
      delete: "Delete",
      name: "Name",
      last_name: "Last Name",
      gender: "Gender",
      age: "Age",
      country: "Country",
      state: "State",
      male: "Male",
      female: "Female",
      study_name: "Study Name",
      description: "Description (Age, Social Stratum, Geographic Area)",
      study_type: "Study Type",
      delete_confirm: "Delete",
    },
    itemsVisibility: {
      deleteSubjectVideo: "Delete Subject Video",
      deleteStimulusVideo: "Delete Stimulus Video",
      calibrationFormLink: "Calibration Form Link",
      shareSubjectProfile: "Share Subject Profile",
      unShareSubjectProfile: "Make Subject Profile Private",
    },
    group: {
      new_subject: "New Subject",
      edit_group: "Edit Group",
      delete_group: "Delete Group",
      group_title: "Group",
      description_title: "Description",
      name_header: "Name",
      last_name_header: "Last Name",
      age_header: "Age",
      gender_header: "Gender",
      state_header: "State",
      country_header: "Country",
      actions_header: "Actions",
      create_subjects_message: "You can now create subjects in this group.",
    },
    subject: {
      subject: "Subject",
    },
    report: {
      notes_start: "From",
      notes_end: "To",
      title: "Report",
      view_report: "View report",
      emotions_report: "Emotions report",
      downloadPdf: "Download PDF",
      noSubjects: "No subjects in this group",
      noEmotions: "No emotions data in this user",
      governingEmotion: "Governing Emotion",
      governingVoiceEmotion: "Governing Voice",
      emotionLoad: "Emotional Load",
      facialExpressions: "Facial Expressions",
      voiceExpressions: "Voice Emotions",
      voice: "Voice",
      noFacialEmotionData: "No facial emotion data for this user",
      noVoiceEmotionData: "No voice emotion data for this user",
      threshold: "Threshold",
    },
    start: {
      create_group_to_start: "To start, create a group",
    },
  },
  es: {
    password: {
      newPassword: "Nueva Contraseña",
      changeButton: "Cambiar Contraseña",
      changeSuccessful: "Cambio de Contraseña Exitoso",
    },
    username:{
      changeName: "Cambiar Nombre",
      changeNameSuccessful: "Cambio de Nombre Exitoso",
    },
    language:{
      title: "Idioma",
    },
    toast: {
      deleteSubjectVideo: "Video del sujeto eliminado",
      deleteStimulusVideo: "Video del estímulo eliminado",
      calibrationFormLink:
        "Enlace del formulario de calibración copiado al portapapeles",
      share:
        "Este perfil de sujeto ahora es público. URL copiada al portapapeles",
      unShare: "Este perfil de sujeto ahora es privado",
    },
    calibration_status: "No se tiene en cuenta",
    profile: "Perfil",
    logout: "Cerrar sesión",
    video_processing: "Procesando video...",
    notes:"Notas",
    project: {
      new_project: "Nuevo Proyecto",
    },
    table: {
      name: "Nombre",
      description: "Descripción",
      type: "Tipo",
      creation_date: "Fecha de Creación",
      actions: "Acciones",
    },
    video: {
      subject_button: "Subir Video del Sujeto",
      project_button: "Subir Imagen o Video para el proyecto",
      menu_item: "Eliminar Video",
      menu_item_cancel: "Cancelar",
      snackbar_close: "Cerrar",
    },
    nav: {
      home: "Inicio",
      about: "Acerca de",
    },
    login: {
      email_label: "Correo electrónico",
      password_label: "Contraseña",
      login_button: "Iniciar sesión",
      forgot_password_link: "¿Olvidaste tu contraseña?",
      error_invalid_email: "Formato de correo electrónico inválido",
      error_wrong_credentials: "Correo electrónico o contraseña incorrectos",
      error_close_button: "Cerrar",
    },
    about: {
      header: "Sobre nosotros",
    },
    groups: {
      add_group: "Añadir Grupo",
      new_group: "Nuevo Grupo",
      group_name: "Nombre del grupo",
      description: "Descripción",
      accept: "Aceptar",
      cancel: "Cancelar",
    },
    dialog: {
      project: "Proyecto",
      edit: "Editar",
      new: "Nuevo",
      accept: "Aceptar",
      cancel: "Cancelar",
      delete: "Eliminar",
      name: "Nombre",
      last_name: "Apellido",
      gender: "Género",
      age: "Edad",
      country: "País",
      state: "Estado",
      male: "Masculino",
      female: "Femenino",
      study_name: "Nombre del estudio",
      description: "Descripción (Edad, Estrato Social, Área Geográfica)",
      study_type: "Tipo de estudio",
      delete_confirm: "Eliminar",
    },
    itemsVisibility: {
      deleteSubjectVideo: "Eliminar Video del Sujeto",
      deleteStimulusVideo: "Eliminar Video del Estímulo",
      calibrationFormLink: "Enlace del Formulario de Calibración",
      shareSubjectProfile: "Compartir Perfil del Sujeto",
      unShareSubjectProfile: "Hacer el Perfil del Sujeto Privado",
    },
    group: {
      new_subject: "Nuevo Sujeto",
      edit_group: "Editar Grupo",
      delete_group: "Eliminar Grupo",
      group_title: "Grupo",
      description_title: "Descripción",
      name_header: "Nombre",
      last_name_header: "Apellido",
      age_header: "Edad",
      gender_header: "Género",
      state_header: "Estado",
      country_header: "País",
      actions_header: "Acciones",
      create_subjects_message: "Ahora puedes crear sujetos en este grupo.",
    },
    subject: {
      subject: "Sujeto",
    },
    report: {
      notes_start: "Desde",
      notes_end: "Hasta",
      title: "Informe",
      view_report: "Ver informe",
      emotions_report: "Informe de emociones",
      downloadPdf: "Descargar PDF",
      noSubjects: "No hay sujetos en este grupo",
      noEmotions: "No hay datos de emociones en este usuario",
      governingEmotion: "Emoción Dominante",
      governingVoiceEmotion: "Emoción de Voz Dominante",
      emotionLoad: "Carga Emocional",
      facialExpressions: "Expresiones Faciales",
      voiceExpressions: "Emociones en Voz",
      voice: "Voz",
      noFacialEmotionData:
        "No hay datos de emociones faciales para este usuario",
      noVoiceEmotionData: "No hay datos de emociones de voz para este usuario",
      threshold: "Umbral",
    },
    start: {
      create_group_to_start: "Para comenzar, crea un grupo",
    },
  },
};
export default createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE, // <--- 1
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE, // <--- 2
  legacy: false,
  messages,
});
export const EMOTIONS_FACE = {
  en: [
    "Anger",
    "Disgust",
    "Fear",
    "Happiness",
    "Sadness",
    "Surprise",
  ],
  es: ["Ira", "Desagrado", "Miedo", "Alegría", "Tristeza", "Sorpresa"],
};
export const EMOTIONS_VOICE = {
  en: [
    "Anger",
    "Disgust",
    "Fear",
    "Happiness",
    "Sadness",
    "Surprise",
  ],
  es: [
    "Ira",
    "Desagrado",
    "Miedo",
    "Alegría",
    "Tristeza",
    "Sorpresa",
  ],
};

// export const EMOTIONS_LABELS = [...EMOTIONS_FACE, ...EMOTIONS_VOICE];
export const SHORT_EMOTIONS = [
  "Ira",
  "Desagrado",
  "Miedo",
  "Alegría",
  "Tristeza",
  "Sorpresa",
];
export const projectsTypes = {
  es: {
    1: "Entrevista",
    2: "Evaluación de Contenido",
    3: "Multiples Sujetos",
  },
  en: {
    1: "Interview",
    2: "Content Evaluation",
    3: "MultiSubject",
  },
};
