<template>
  <v-container>

    <div v-if="!loadingGroupsSubjects">
      <div v-if="(resultSubjectCalibration?.subjects_by_pk?.calibration === null && formCompleted === false)">
        <v-row>
          <v-col cols="6">
            <img src="@/assets/logo-emoscopio.png" width="196" height="51"/>
            <div class="text-h3">Test de Calibración</div>
            <div>
              El siguiente cuestionario es sobre su estado de ánimo en las últimas
              semanas. Lea cada afirmación y elija la respuesta que corresponda a
              cada caso.
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row dense>
              <v-col
                  cols="12"
                  v-for="(question, index) in shuffledQuestions"
                  :key="index"
              >
                <v-row>
                  <v-col>
                    <v-card>
                      <div style="margin: 10px">
                        <v-radio-group
                            :label="question.label"
                            v-model="item[question.index]"
                        >
                          <v-radio label="No aplica" :value="1"></v-radio>
                          <v-radio
                              label="Aplica ligeramente"
                              :value="2"
                          ></v-radio>
                          <v-radio
                              label="Aplica moderadamente"
                              :value="3"
                          ></v-radio>
                          <v-radio
                              label="Aplica frecuentemente"
                              :value="4"
                          ></v-radio>
                          <v-radio
                              label="Aplica completamente"
                              :value="5"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
                variant="tonal"
                rounded
                :disabled="!allQuestionsAnswered"
                @click="saveAnswers"
            >Guardar
            </v-btn>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar">
          Datos Guardados
          <template v-slot:actions>
            <v-btn color="pink" variant="text" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
        <div v-if="!allQuestionsAnswered"></div>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12">
            <img src="@/assets/logo-emoscopio.png" width="196" height="51"/>
            <div class="text-h4 text-center">
              Gracias por haber completado el test de calibración.
            </div>
          </v-col>
        </v-row>

      </div>
    </div>
  </v-container>
</template>
<script setup>
import {computed, ref} from "vue";
// create a apollo mutation
import {useMutation, useQuery} from "@vue/apollo-composable";
import {useRoute} from "vue-router";
import {GET_SUBJECTS_CALIBRATION, UPDATE_SUBJECT_CALIBRATION,} from "@/queries/subjects";

const snackbar = ref(false);
const item = ref({});

const allQuestionsAnswered = computed(() => {
  return questions.every((question) => item.value[question.key]);
});

const route = useRoute();
const saveAnswers = () => {
  updateCalibration({id: route.params.subjectId, calibration: item.value});
  // refetcSubjectCalibration({ id: route.params.subjectId });
  console.log("Saved answers:", item.value);
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  // Add a number at the beginning of each question

  for (let i = 0; i < array.length; i++) {
    array[i].label = `${i + 1}. ${array[i].label}`;
  }

  return array;
}

const questions = [
  {
    key: "1",
    label: "¿He estado alegre?",
    index: 1,
  },
  {
    key: "2",
    label: "¿He tenido poca energía?",
    index: 2,
  },
  {
    key: "3",
    label: "¿Me sentí triste?",
    index: 3,
  },

  {
    key: "4",
    label: "¿Me sentí nervioso?",
    index: 4,
  },
  {
    key: "5",
    label: "¿He podido reírme de muchas cosas?",
    index: 5,
  },
  {
    key: "6",
    label: "¿Me sentí feliz?",
    index: 6,
  },
  {
    key: "7",
    label: "¿Me sentí relajado?",
    index: 7,
  },
  {
    key: "8",
    label: "¿Me he sentido sereno?",
    index: 8,
  },
  {
    key: "9",
    label: "¿Me sentí lleno de energía?",
    index: 9,
  },
  {
    key: "10",
    label: "¿Me sentí deprimido?\n",
    index: 10,
  },
  {
    key: "11",
    label: "¿Me sentí ansioso?",
    index: 11,
  },
  {
    key: "12",
    label: "¿He estado tenso?",
    index: 12,
  },
  {
    key: "13",
    label: "¿Me sentí lento?",
    index: 13,
  },
  {
    key: "14",
    label: "¿Me sentí activo?",
    index: 14,
  },
  {
    key: "15",
    label: "¿Me sentí afligido?",
    index: 15,
  },
  {
    key: "16",
    label: "¿Me sentí vigoroso?",
    index: 16,
  },
  {
    key: "17",
    label: "¿Sentí calma?",
    index: 17,
  },
  {
    key: "18",
    label: "¿Me sentí lleno de energía?",
    index: 18,
  },
];
let shuffledQuestions = shuffleArray(questions);
let formCompleted = ref(false);

const {mutate: updateCalibration, onDone: onUpdateCalibration} = useMutation(
    UPDATE_SUBJECT_CALIBRATION
);


onUpdateCalibration(() => {
  snackbar.value = true;
  formCompleted.value = true;
});
const {
  loading: loadingGroupsSubjects,
  result: resultSubjectCalibration,
  refetch: refetcSubjectCalibration,
} = useQuery(GET_SUBJECTS_CALIBRATION, {
  id: route.params.subjectId,
  fetchPolicy: 'network-only',
});

const showForm = computed(() => {
  console.log("calibration data", resultSubjectCalibration?.subjects_by_pk?.calibration)
  console.log("formCompleted", formCompleted.value)
  return resultSubjectCalibration?.subjects_by_pk?.calibration === "" || formCompleted.value === false;


});
</script>
