<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="text-h5">Agregar Usuario</div>
        <v-text-field
          label="email"
          v-model="user.email"
          required
        ></v-text-field>
        <v-text-field
          label="password"
          v-model="user.password"
          required
          type="password"
        ></v-text-field>
        <v-btn
            variant="tonal"
            rounded
            @click.prevent="handleUserSingUp">Crear Usuario</v-btn>
        <v-table>
          <thead>
            <tr>
              <th class="text-left">Nombre</th>
              <th class="text-left">Email</th>
              <th class="text-left">Ultima Actualizacion</th>
              <th class="text-left">Deshabilitado</th>
              <th class="text-left">Email Verificado</th>
              <th class="text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subject in resultUsers?.users" :key="subject?.id">
              <td>{{ subject?.displayName }}</td>
              <td>{{ subject?.email }}</td>
              <td>{{ subject?.updatedAt }}</td>
              <td>{{ subject?.disabled }}</td>
              <td>{{ subject?.emailVerified }}</td>

              <td>
                <v-row>
                  <v-btn
                    variant="tonal"
                    rounded
                    @click="
                      queyrUpdateDisable({
                        id: subject.id,
                        disabled: !subject.disabled,
                      })
                    "
                  >
                    <v-icon> mdi-eye</v-icon>
                  </v-btn>

                </v-row>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" vertical>
      {{ snackText }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script setup>
// get user data using apollo useQuery hook
import { ref, watchEffect } from "vue";
let snackbar = ref(false);
let snackText = ref("");
let user = ref({ email: "", password: "" });
// import { useInviteUser } from "@nhost/vue";
import { useQuery, useMutation } from "@vue/apollo-composable";

import { GET_USERS, UPDATE_USER_DISABLED } from "@/queries/users";
import { useSignUpEmailPassword } from "@nhost/vue";

const { mutate: queyrUpdateDisable, onDone: onDoneUpdateDisable } =
  useMutation(UPDATE_USER_DISABLED);
const { result: resultUsers, refetch: refetchUsers } = useQuery(GET_USERS);

const {
  signUpEmailPassword,
  needsEmailVerification,
  isLoading,
  isSuccess,
  isError,
  error,
} = useSignUpEmailPassword();

const handleUserSingUp = async () => {
  console.log("usuario creado", user.value);
  // await signUpEmailPassword(user.value.email, user.value.password);
  const result = await signUpEmailPassword(
    user.value.email,
    user.value.password
  );
  if (result.error) {
    snackText.value = result.error.error;

    console.log(result.error);
  } else {
    snackText.value = "Usuario creado";
    refetchUsers();
  }
  snackbar.value = true;

  console.log(
    needsEmailVerification.value,
    isLoading.value,
    isSuccess.value,
    isError.value,
    error.value
  );
};

const handleResetPassword = async () => {
  console.log("handleResetPassword");
};

// nhost.auth.resetPassword({ email: 'joe@example.com' })
// watchEffect(() => {
//   console.log(
//     needsEmailVerification.value,
//     isLoading.value,
//     isSuccess.value,
//     isError.value,
//     error.value
//   );
// });
</script>
