<script setup>
import { ref, watchEffect } from "vue";
import { useChangePassword } from "@nhost/vue";

// const router = useRouter();
const password = ref("");

const { changePassword, isLoading, isSuccess, isError, error } =
  useChangePassword();

watchEffect(() => {
  console.log(isLoading.value, isSuccess.value, isError.value, error.value);
});

const handleFormSubmit = async () => {
  await changePassword(password.value);
};
</script>
<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <div class="text-h5">Emoscopio</div>
          <div>Recibira un correo electronico</div>
          <v-text-field
            v-model="password"
            label="contraseña"
            required
            type="password"
          ></v-text-field>
          <v-btn
              variant="tonal"
              rounded
              color="success" class="me-4" @click.prevent="handleFormSubmit">
            Cambias Contrasena
          </v-btn>
          <div v-if="isSuccess">Contrasena cambiada exitosamente.</div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
