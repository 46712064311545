<template>
  <v-row style="border-bottom: 1px solid #232323">
    <v-col>
      <!--      <v-btn @click="router.go(-1)">go back</v-btn>-->
      <RouterLink
        :to="{ name: 'home' }"
        style="text-decoration: none; color: inherit"
      >
        <img src="@/assets/logo-emoscopio.png" width="196" height="51" />
      </RouterLink>
    </v-col>
    <v-col class="align-center justify-center d-flex">
      <div class="text-h5" style="color: gray">
        {{ props.title }}
        <span v-if="props.subTitle">:</span> {{ props.subTitle }}
      </div>
    </v-col>

    <v-col  class="d-flex justify-end align-center">
      <slot v-if="isAuth" name="actions"></slot>
      <v-menu v-if="isAuth">
        <template v-slot:activator="{ props }">

          <a
            href="https://www.notion.so/argenisleon/Emoscopio-b71db24ad2754d69b5f44251629961a5"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none; color: inherit"
          >
            <v-btn variant="plain">
              <v-icon size="x-large"> mdi-help-circle-outline </v-icon>
            </v-btn>
          </a>
          <v-btn v-bind="props" variant="plain" class="me-4">
            <v-avatar color="info" :image="userAvatarUrl"> 32 </v-avatar></v-btn
          >
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in [
              { id: 1, title: t('profile')},
              { id: 2, title: t('logout') },

            ]"
            :key="index"
            :value="index"
            @click="handleMenuItemClick(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useNhostClient, useSignOut, useUserAvatarUrl } from "@nhost/vue";
import {useI18n} from "vue-i18n";
const { t } = useI18n();
import { computed } from "vue";
const userAvatarUrl = useUserAvatarUrl();
const { nhost } = useNhostClient();

const router = useRouter();
const { signOut } = useSignOut();
const logout = () => {
  signOut();
  router.push("/login");
};

const isAuth = computed(() => {
  return nhost.auth.isAuthenticated();
});

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  subTitle: {
    type: String,
    default: "",
  },
});

const route = useRoute();
const handleMenuItemClick = (item) => {
  if (item.id === 4) {
    return false;
  } else if (item.id === 2) {
    logout();
  } else if (item.id === 1) {
    router.push(`/profile/${route.params.subjectId}`);
  } else if (item.id === 3) {
    i18next
        .changeLanguage('en')
        .then((t) => {
          t('key'); // -> same as i18next.t
        });
  }

};
</script>
