<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <HeaderApp/>
      </v-col>
    </v-row>

    <!--    <v-row>-->
    <!--      <v-col>-->
    <!--        <div class="text-h5">Cambiar Nombre</div>-->
    <!--        <v-text-field-->
    <!--          v-model="displayName"-->
    <!--          label="nombre"-->
    <!--          required-->
    <!--        ></v-text-field>-->
    <!--        <v-btn @click="handleDisplayNameChange"> Cambiar nombre </v-btn>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <div class="text-h5">Name</div>
            <v-text-field
                v-model="displayName"
                :label="$t('username.changeName')"
                required
            ></v-text-field>
            <v-btn @click="handleChangeName">
              {{ $t("username.changeName") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-h5">{{ $t("password.newPassword") }}</div>
            <v-text-field
                v-model="password"
                :label="$t('password.changeButton')"
                required
                type="password"
            ></v-text-field>
            <v-btn @click="handleChangePassword">
              {{ $t("password.changeButton") }}
            </v-btn>
          </v-col>
        </v-row>
        <!--      Language Switch-->
        <v-row>
          <v-col>
            <div class="text-h5">{{ $t("language.title") }}</div>
            <v-switch label="Español" @change="handleLanguageChange"></v-switch>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar">
          {{ snackText }}
          <template v-slot:actions>
            <v-btn color="pink" variant="text" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { useChangePassword, useNhostClient, useUserId, useUserDisplayName } from "@nhost/vue";
import { useMutation } from "@vue/apollo-composable";
import {ref, watchEffect} from "vue";
import HeaderApp from "@/components/HeaderApp.vue";
import {useI18n} from "vue-i18n";
import { nextTick } from "vue";
import { UPDATE_USER_NAME } from "@/queries/users";

const userId = useUserId();

const { mutate: queryUpdateName } =
  useMutation(UPDATE_USER_NAME);

const {t} = useI18n();
const {changePassword, isLoading, isSuccess, isError, error} =
    useChangePassword();
const userDisplayName = useUserDisplayName();

watchEffect(() => {
  console.log(isLoading.value, isSuccess.value, isError.value, error.value);
});
const displayName = ref(userDisplayName.value);
const password = ref("");

let snackText = ref("");
let snackbar = ref(false);

const handleChangeName = async (name) => {
  try {
    const response = await queryUpdateName({
      id: userId.value,
      name: displayName.value
    });
    if (response) {
      snackbar.value = true;
      snackText.value = t("username.changeNameSuccessful");
    }
  } catch (error) {
    console.error(error);
    snackbar.value = true;
    snackText.value = 'Error cambiando nombre de usuario';
  }


};

const { locale } = useI18n();

const handleLanguageChange = async (e) => {
  e.preventDefault();
  locale.value = locale.value === 'es' ? 'en' : 'es';
  nextTick(() => {
    snackbar.value = true;
    snackText.value = t("username.changeNameSuccessful");
  })
};
const handleChangePassword = async (e) => {
  e.preventDefault();

  const response = await changePassword(password);
  if (response) {
    snackbar.value = true;
    snackText.value = t("password.changeSuccessful");
  }
};

const handleDisplayNameChange = async (e) => {
  e.preventDefault();
  const response = await userDisplayName(displayName);
  if (response) {
    snackbar.value = true;
    snackText.value = $t("password.changeSuccessful");
  }
};
</script>
