<template>
  <v-dialog
    v-model="localVisible"
    v-if="props.mode === `new` || props.mode === `edit`"
    width="40%"
    @keydown.esc="closeDialog"
    persistent
  >
    <v-card>
      <v-form ref="form" fast-fail @submit.prevent>
        <v-card-title
          >{{
            props.mode === `edit`
              ? `Editar ${props.itemName}`
              : `Nuevo ${props.itemName}`
          }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newItem.name"
            density="compact"
            label="Nombre"
            required
            rounded
            :rules="nameRules"
          ></v-text-field>
          <v-text-field
            v-model="newItem.description"
            density="compact"
            label="Descripción"
            required
            rounded
            :rules="descriptionRules"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="blue-darken-1"
            variant="text"
            @click="submitForm"
            >Aceptar
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="emit(`update:visible`, false)"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>

  <!--  Dialog for delete -->
  <v-dialog
    persistent
    v-if="props.mode === `delete`"
    v-model="localVisible"
    width="40%"
  >
    <v-card>
      <v-card-title>Eliminar {{ props.itemName }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="blue-darken-1"
          variant="text"
          @click="OnClickDeleteItem"
          >Aceptar</v-btn
        >
        <v-btn
          type="submit"
          color="blue-darken-1"
          variant="text"
          @click="emit(`update:visible`, false)"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import router from "@/router";

const closeDialog = () => {
  emit("update:visible", false);
};
import { descriptionRules, nameRules } from "@/validation-rules";
import { ref, watch, watchEffect } from "vue";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: "new",
  },
  itemId: {
    type: Number,
    default: null,
  },
  itemDefaultValue: {
    type: Object,
    default: () => {},
  },
  getQuery: {
    default: () => {},
  },
  createQuery: {
    default: () => {},
  },
  updateQuery: {
    default: () => {},
  },
  deleteQuery: {
    default: () => {},
  },
  itemName: {
    type: String,
    default: "",
  },
  params: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits([
  "update:visible",
  "trigger:refetchCreate",
  "trigger:refetchUpdate",
  "trigger:refetchDelete",
]);

const localVisible = ref(props.visible);
watch(
  () => props.visible,
  (newValue) => {
    localVisible.value = newValue;
  }
);

const newItem = ref(props.itemDefaultValue);
const form = ref();
const { mutate: queryCreateItem, onDone: onCreateItemDone } = useMutation(
  props.createQuery
);

const { load: queryLoadItems, onResult: queryResultItems } = useLazyQuery(
  props.getQuery
);

const { mutate: queryUpdateItem, onDone: onUpdateItemDone } = useMutation(
  props.updateQuery
);

const { mutate: queryDeleteItem, onDone: onDeleteItemDone } = useMutation(
  props.deleteQuery
);

const onClickCreateItem = () => {
  // if (!newProject.value.title || !newProject.value.description)
  //   return alert("Falta llenar campos");
  console.log(newItem.value);
  const variables = { ...newItem.value, ...props.params };

  // Ensure that all keys in itemDefaultValue have a value in the variables object
  for (const key in props.itemDefaultValue) {
    if (
      !Object.prototype.hasOwnProperty.call(variables, key) ||
      variables[key] === null
    ) {
      variables[key] = props.itemDefaultValue[key];
    }
  }
  console.log("variables", variables);
  queryCreateItem(variables);
};

const cleanForm = () => {
  newItem.value = props.itemDefaultValue;
};
onCreateItemDone((value) => {
  console.log("onCreateItemDone", value);
  cleanForm();
  // router.push(`/project/${data?.data?.insert_projects_one?.id}`)
  // router.push({ name: "subjects" });
  emit("update:visible", false);
  emit("trigger:refetchCreate");
});

const submitForm = async () => {
  const { valid } = await form.value.validate();
  if (valid) {
    if (props.mode === `edit`) onClickUpdateItem();
    else onClickCreateItem();
  }
};

const onClickUpdateItem = () => {
  // Update
  const variables = { ...newItem.value, ...props.params };
  console.log("variables", newItem.value);
  // Ensure that all keys in itemDefaultValue have a value in the variables object
  for (const key in props.itemDefaultValue) {
    // conver key to camelCase

    if (
      !Object.prototype.hasOwnProperty.call(variables, key) ||
      variables[key] === null
    ) {
      variables[key] = props.itemDefaultValue[key];
    }
  }
  console.log("variables", variables);
  queryUpdateItem(variables);
};
onUpdateItemDone(() => {
  cleanForm();
  emit("update:visible", false);
  emit("trigger:refetchUpdate");
});
watchEffect(() => {
  console.log(props.mode, props.itemId);
  if (props.mode === "new") {
    cleanForm();
  } else if (props.mode === "edit") {
    queryLoadItems(
      props.getQuery,
      { id: props.itemId },
      { fetchPolicy: "network-only" }
    );
  }
});

queryResultItems((result) => {
  // Get the values of the result.data object
  const dataValues = Object.values(result.data);

  // Check if dataValues is not empty and access the first value (projects_by_pk)
  const itemData = dataValues.length > 0 ? dataValues[0] : null;

  // Check if projectData is not null, then extract the values
  if (itemData) {
    // Loop through the keys of the itemDefaultValue object
    for (const key in props.itemDefaultValue) {
      console.log("itemData", itemData[key], key, itemData[0]);
      // Check if the projectData object has the property
      if (Object.prototype.hasOwnProperty.call(itemData, key)) {
        // Assign the value from projectData to newItem.value

        newItem.value[key] = itemData[key];
      }
    }
  }
});

const OnClickDeleteItem = () => {
  queryDeleteItem({ id: props.itemId });
  emit("update:visible", false);
  emit("trigger:refetchDelete");
};

onDeleteItemDone(() => {
  emit("trigger:refetch");
});
</script>
