export const EMOTIONS_COLORS = [
  "#ED755A", // light red
  "#73AA6B", // light green
  "#7F6DA0", // blue gray
  "#F9B75B", // light blue
  "#62AFE1", // light yellow
  "#AA69A0", // light pink

  "#E0E0E0", // light gray
  "#E57373", // light red
  "#81C784", // light green
  "#B0BEC5", // blue gray
  "#64B5F6", // light blue
  "#FFF176", // light yellow
  "#F06292", // light pink

  "#E0E0E0", // light gray
];

export const VIDEO_FORMATS = [".mp4", ".mov", ".avi"];
export const IMAGE_FORMATS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
  ".svg",
  ".heic",
];
