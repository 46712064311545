<template>
  <div>
    <canvas
      style="display: block; background-color: transparent"
      ref="canvas"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
      @click="handleClick"
    ></canvas>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { drawTimeline, formatTime } from "@/helpers";

const props = defineProps({
  series: {
    type: Array,
    default: () => [],
  },
  colors: {
    type: String,
  },
  time: {
    type: Number,
  },
  mode: {
    type: String,
    default: "static",
  },
  isStepChart: {
    type: Boolean,
    default: false,
  },
  threshold: {
    type: Number || String || null,
    default: null,
  },
  height: {
    type: Number || String || null,
    default: 100,
  },
  seriesVisibility: {
    type: Array || null,
    default: null,
  },
  showLabels: {
    type: Boolean,
    default: true,
  },
  showGridLines: {
    type: Boolean,
    default: true,
  },
  backgroundColor: {
    type: String,
    default: "transparent",
  },
});

const emit = defineEmits(["update:line", "playhead:move", "playhead:click"]);
const time = ref(props.time);
const canvas = ref(null);
const ctx = ref(null);
const lastLineX = ref(null);
// const lineColor = ref(["purple", "blue"]);

// Mouse events
let isMouseDown = false;
const handleMouseDown = () => {
  if (props.mode === "static") {
    return;
  }
  isMouseDown = true;
};
const handleMouseUp = () => {
  if (props.mode === "static") {
    return;
  }
  isMouseDown = false;
};

const calculateTime = (event) => {
  // const x = event.clientX - canvas.value.offsetLeft;
  const x = event.offsetX;
  time.value = Math.floor(x / (canvas.value.width / props.series[0].length));
};

const handleMouseMove = (event) => {
  if (props.mode === "static") {
    return;
  }
  if (isMouseDown) {
    calculateTime(event);
    drawPlayLine(time.value);
    emit("playhead:move", time.value);
  }
};

const handleClick = (event) => {
  if (props.mode === "static") {
    return;
  }
  calculateTime(event);
  drawPlayLine(time.value);
  emit("playhead:click", time.value);
};

const drawPlayLine = (time) => {
  if (props.series.length === 0 || props.series[0] === null) {
    return;
  }
  lastLineX.value = time * (canvas.value.width / props.series[0].length);
  drawChart();
};

const fontColor = ref("#ff0000");
const drawChart = () => {
  if (props.series.length === 0 || props.series[0] === null) {
    return;
  }
  ctx.value.fillStyle = props.backgroundColor;

  // Initialize canvas
  const marginBottom = 30; // set a margin at the bottom

  ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height); // subtract the margin from the canvas height
  const maxValue = Math.max(1);

  // const maxValue = Math.max(...props.values[0]);
  const scale = (canvas.value.height - marginBottom) / maxValue; // subtract the margin from the scale calculation

  // Bottom line
  ctx.value.fillStyle = fontColor.value;
  ctx.value.font = "10px Arial";

  ctx.value.lineWidth = 2;
  ctx.value.beginPath();
  ctx.value.moveTo(0, canvas.value.height - marginBottom); // subtract the margin from the y-coordinate
  ctx.value.lineTo(canvas.value.width, canvas.value.height - marginBottom); // subtract the margin from the y-coordinate
  ctx.value.stroke();
  ctx.value.lineWidth = 1;

  // Draw horizontal gray lines
  if (props.showGridLines) {
    ctx.value.fillStyle = "#000000";
    ctx.value.setLineDash([1, 5]);
    for (let i = 0; i < canvas.value.height - marginBottom; i += 20) {
      // subtract the margin from the canvas height
      if (i !== 0) {
        ctx.value.beginPath();
        ctx.value.moveTo(0, i);
        ctx.value.lineTo(canvas.value.width, i);
        ctx.value.stroke();
      }
    }
  }
  ctx.value.setLineDash([]);
  ctx.value.fillStyle = "#999999";
  let colors;
  // if props.colors is a string or number convert it to an array of a single element
  if (typeof props.colors === "string" || typeof props.colors === "number") {
    colors = [props.colors];
  } else {
    colors = props.colors;
  }

  // Calculate the threshold
  let series_threshold;
  // if threshold is not null

  if (props.threshold !== null) {
    series_threshold = props.series.map((series) => {
      return series.map((value) => {
        return Math.abs(value) >= props.threshold ? 1 : 0;
      });
    });
  } else {
    series_threshold = props.series;
    // if (props.series)
    //   series_threshold = props.series.map((series) => {
    //     if (series)
    //       return series.map(value => Math.abs(value));
    //
    //   });
  }
  ctx.value.lineWidth = 2;
  const listLinesDash = [
    [0, 0],
    [1, 1],
  ];

  // Draw the time series
  for (let j = 0; j < series_threshold.length; j++) {
    if (props.seriesVisibility && !props.seriesVisibility[j]) {
      continue;
    }
    ctx.value.strokeStyle = colors[j];
    ctx.value.beginPath();
    if (props.isStepChart) {
      // Start at the first point
      ctx.value.moveTo(
        0,
        canvas.value.height - marginBottom - series_threshold[j][0] * scale
      );
      for (let i = 0; i < series_threshold[j].length; i++) {
        const x = i * (canvas.value.width / series_threshold[j].length);
        const y =
          canvas.value.height - marginBottom - series_threshold[j][i] * scale;
        if (series_threshold[j][i] === series_threshold[j][i - 1]) {
          // Draw a horizontal line
          ctx.value.lineTo(x, y);
        } else {
          // Draw a vertical and horizontal line
          ctx.value.lineTo(
            x,
            canvas.value.height -
              marginBottom -
              series_threshold[j][i - 1] * scale
          );
          ctx.value.lineTo(x, y);
        }
      }
    } else {
      // Draw a normal chart
      ctx.value.moveTo(series_threshold[0], canvas.value.height);
      ctx.value.setLineDash(listLinesDash[j]);
      for (let i = 0; i < series_threshold[j]?.length; i++) {
        ctx.value.lineTo(
          i * (canvas.value.width / series_threshold[j].length),
          canvas.value.height - marginBottom - series_threshold[j][i] * scale
        );
      }
    }
    ctx.value.stroke();
  }

  // Draw the player line
  if (props.mode === "interactive") {
    ctx.value.lineWidth = 3;
    ctx.value.setLineDash([]);
    if (lastLineX.value !== null) {
      ctx.value.strokeStyle = "#2C8AEA";
      // set line bolder
      ctx.value.beginPath();
      ctx.value.moveTo(lastLineX.value, 0);
      ctx.value.lineTo(lastLineX.value, canvas.value.height); // subtract the margin from the y-coordinate
      ctx.value.stroke();
    }
  }
  ctx.value.fillText(
    props.series[0][time.value],
    canvas.value.height - 50,
    canvas.value.width - 50
  ); // move the text up by 5 pixels

  // Print time ticks
  ctx.value.lineWidth = 1;
  ctx.value.strokeStyle = "#cccccc";

  const seconds = props.series[0].length; // assuming all series have same length
  const totalTimeHints = 20;

  drawTimeline(
    ctx,
    seconds,
    marginBottom,
    canvas.value.width,
    canvas.value.height,
    totalTimeHints
  );
};

onMounted(() => {
  const parent = canvas.value.parentNode;
  canvas.value.width = parent.offsetWidth;
  canvas.value.height = props.height;
  ctx.value = canvas.value.getContext("2d");
  drawChart();

  drawPlayLine(props.time);
});

onBeforeUnmount(() => {
  canvas.value = null;
  ctx.value = null;
});

watch(
  () => props.time,
  (newVal) => {
    drawPlayLine(newVal);
  }
);

watch(
  () => props.series,
  () => {
    drawChart();
  }
);

watch(
  () => props.threshold,
  () => {
    drawChart();
  }
);

watch(time, (newValue) => {
  emit("update:line", newValue);
});
</script>
