<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useResetPassword } from "@nhost/vue";

// const router = useRouter();
const email = ref("");

const { resetPassword, isLoading, isSent, isError, error } = useResetPassword();

watchEffect(() => {
  console.log(isLoading.value, isSent.value, isError.value, error.value);
});

const handleFormSubmit = async () => {
  await resetPassword(email.value);
};
</script>
<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <div class="text-h5">Emoscopio</div>
          <div v-if="isSent">
            Recibira un correo electronico con un link para reiniciar su contraseña.
          </div>
          <div v-else>
            Ingrese su correo electronico para reiniciar su contraseña.
            <v-text-field v-model="email" label="email" required></v-text-field>
            <v-btn
                variant="tonal"
                rounded
              color="success"
              class="me-4"
              @click.prevent="handleFormSubmit"
            >
              Enviar correo
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
