<template>
  <v-row style="background-color: #232323">
    <v-col cols="3" class="justify-center" style="background-color: #232323">
      {{ $t("report.threshold") }}
      <v-slider
          v-model="slider"
          :max="1"
          :min="0"
          :step="0.1"
          show-ticks="always"
          tick-size="4"
          :ticks="[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]"
      ></v-slider>
    </v-col>
    <v-col class="d-flex justify-end align-center">
      <v-btn color="info" rounded @click="generatePdf">
        {{ $t("report.downloadPdf") }}
      </v-btn>
    </v-col>
  </v-row>
  <v-row id="report">

    <v-col style="background-color: #232323; overflow: auto; height: 82vh">
      <div
          ref="HtmlReport"
          v-if="props?.nodes?.projects[0]"
          style="margin: 0 40px 0 40px"
      >
        <img src="@/assets/report_banner_no_title.jpg" style="width: 100%"/>
        <div class="text-h3 text-center" style="margin: 50px 0">
          {{ $t("report.title") }}
        </div>
        <div class="text-center">
          <!--          <div class="text-h3">Informe de resultados</div>-->
          <div class="text-h4">{{ props?.nodes?.projects[0]?.title }}</div>
          <div class="text-h4">
            {{ props?.nodes?.projects[0]?.description }}
          </div>
        </div>

        {{ props?.nodes?.projects[0]?.desciption }}
        <div
            v-for="group in props?.nodes?.projects[0].groups"
            :key="group.id"
            class="pa-0 ma-0"
        >
          <v-row>
            <v-col>
              <div style="margin: 20px 0">
                <div class="text-h4">{{ group?.name }}</div>
                <div>{{ group?.description }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="group?.subjects.length === 0">
            <v-col>
              <div>{{ $t("report.noSubjects") }}</div>
            </v-col>
          </v-row>
          <div class="text-h5">Notas</div>
          <div v-for="note in group?.subjects[0]['notes']">
            {{ $t('report.notes_start') }}: {{ note['startSecond'] }}
            {{ $t('report.notes_end') }}:{{ note['endSecond'] }}
            Texto: {{ note['text'] }}
          </div>
          <v-row v-if="group?.subjects.length === 0">
            <v-col>
              <div>{{ $t("report.noSubjects") }}</div>
            </v-col>
          </v-row>
          <v-divider
              style="margin: 50px 0px 50px 0px"
              class="border-opacity-50"
          ></v-divider>
          <div
              v-for="subject in group.subjects"
              :key="subject.id"
              class="pa-0 ma-0"
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="2">
                    <div v-if="calibrationScore(subject?.calibration)">
                      {{ $t("calibration_status") }}
                    </div>
                    <v-avatar
                        style="background-color: #ffffff; object-fit: cover"
                        size="90"
                        rounded="0"
                    >
                      <v-img cover :src="getImage(subject?.thumbnail)"></v-img>
                    </v-avatar>


                    <div class="text-h5 capitalize">
                      {{ subject?.name }} {{ subject?.last_name }}
                    </div>
                    <div>
                      {{ subject?.gender }} - {{ subject?.state }}
                      {{ subject?.country }}
                    </div>
                  </v-col>

                  <!--Highlight-->
                  <v-col class="justify-center d-flex" cols="3" v-if="subject?.face_emotions">
                    <div>
                      <div class="text-h5">Highlights</div>
                      <ul>
                        <li
                            v-for="item in sumEmotions(
                                subject?.face_emotions
                              )"
                            :key="item.index"
                        >
                          <div>Segundo {{ item.index }}</div>
                          <div>
                            {{ $t("report.emotionLoad") }} {{ item.value }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col v-else>
                    <div>{{ $t("report.noFacialEmotionData") }}</div>
                  </v-col>
                  <!--                      Governing Emotions-->
                  <v-col class="justify-center d-flex" cols="3" v-if="subject?.face_emotions">
                    <div style="height: 250px">
                          <span class="text-h5">{{
                              $t("report.governingEmotion")
                            }}</span>
                      <BarChart
                          :data="
                              aggregateUserEmotions(subject?.face_emotions, slider)
                            "
                          :options="{
                              indexAxis: 'y',
                              scales: {
                                x: { ticks: { color: '#FFFFFF' }, max: 100 },
                                y: { ticks: { color: '#FFFFFF' } },
                              },
                            }"
                          :labels="EMOTIONS_FACE[locale]"
                      />
                    </div>
                  </v-col>
                  <v-col v-else>
                    <div>{{ $t("report.noFacialEmotionData") }}</div>
                  </v-col>
                  <!--                      Governing Voice -->
                  <v-col class="justify-center d-flex" cols="3" v-if="subject?.voice_emotions">
                    <div style="height: 250px">
                          <span class="text-h5">{{
                              $t("report.governingVoiceEmotion")
                            }}</span>
                      <BarChart
                          :data="
                              aggregateUserEmotions(subject?.voice_emotions, slider)
                        "
                          :options="{
                                indexAxis: 'y',
                          scales: {
                            x: { ticks: { color: '#FFFFFF' }, max: 100 },
                            y: { ticks: { color: '#FFFFFF' }  },
                          },
                        }"

                          :labels="EMOTIONS_VOICE[locale]"
                      />

                    </div>
                  </v-col>
                  <v-col v-else>
                    <div>{{ $t("report.noVoiceEmotionData") }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--            Governing Emotion-->

            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <div class="text-h5">
                      {{ $t("report.facialExpressions") }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <div></div>
                  <v-col cols="12" v-if="subject?.facial_emotions">
                    <!--                    <div class="text-subtitle-1">Respuestas Emocionales</div>-->
                    <RowsEmotionCharts
                        :colors="EMOTIONS_COLORS"
                        :emotions="subject?.face_emotions"
                        mode="static"
                        :labels="EMOTIONS_FACE[locale]"
                        :is-step-chart="true"
                        :threshold="slider"
                        :height="50"
                        :show-grid-lines="false"
                        background-color="transparent"
                    />
                  </v-col>
                  <v-col v-else>
                    <div>{{ $t("report.noFacialEmotionData") }}</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="text-h5">
                      {{ $t("report.voiceExpressions") }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <div></div>
                  <v-col cols="12" v-if="subject?.voice_emotions">
                    <!--                    <div class="text-subtitle-1">Respuestas Emocionales</div>-->
                    <RowsEmotionCharts
                        :colors="EMOTIONS_COLORS"
                        :emotions="subject?.voice_emotions"
                        mode="static"
                        :labels="EMOTIONS_VOICE[locale]"
                        :is-step-chart="true"
                        :threshold="slider"
                        :height="50"
                        :show-grid-lines="false"
                        background-color="transparent"
                    />
                  </v-col>
                  <v-col v-else>
                    <div>{{ $t("report.noVoiceEmotionData") }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


            <v-divider
                style="margin: 50px 0 50px 0"
                class="border-opacity-25"
            ></v-divider>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
  <!-- This is a copy of the template with the style we want to print -->
</template>

<script setup>
import {computed, ref, toRaw} from "vue";
import BarChart from "@/components/BarChart.vue";

import {useI18n} from "vue-i18n";
import {useLazyQuery} from "@vue/apollo-composable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import RowsEmotionCharts from "@/components/RowsEmotionCharts.vue";
import {GET_SUBJECTS_BY_IDS} from "@/queries/subjects";
import {EMOTIONS_FACE, EMOTIONS_VOICE} from "@/i18n";
import {EMOTIONS_COLORS} from "@/constants";
import {calibrationScore} from "@/helpers";

const {locale} = useI18n();

const props = defineProps({
  nodes: {type: Object},
  config: {type: Object},
  print: {type: Boolean},
});

let userById = ref(null);
let slider = ref(0);
let HtmlReport = ref(null);

const getBase64 = (file) => {

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

const getImage = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const r = await getBase64(blob);
  return r;
}
const {load, onResult} = useLazyQuery(GET_SUBJECTS_BY_IDS);

onResult((arr) => {
  // Convert the result from the graphql to the format user_id:{...user_data}

  if (typeof arr === "undefined") return;
  arr = arr?.data?.subjects;

  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    const {id, ...rest} = arr[i];
    obj[id] = rest;
  }
  userById.value = obj;
});

const r = computed(() => {
  console.log("computed", userById.value);
  return aggregateGroupEmotions(userById.value);
});

const aggregateUserEmotions = (data, threshold) => {
  // Aggregate emotions
  if (!data) {
    return [];
  }

  return data.map((emotion) => {
    const presenceData = emotion.map((v) => Math.abs(v));
    const totalPresence = presenceData.reduce((acc, val) => acc + val, 0);
    // console.log(totalPresence);
    let presencePercentage = (totalPresence / presenceData.length * 100);


    // If presencePercentage is less than threshold, return 0
    if (presencePercentage < threshold * 100) {
      presencePercentage = 0;
    }
    // Convert to the format xx%
    return presencePercentage;
    // return { name: emotion.name, color: emotion.color, presence: presencePercentage };
  });
};
const aggregateGroupEmotions = (subjects) => {
  if (!subjects) {
    return [];
  }
  const emotion_categories = ["face", "voice"];
  const emotion_sums = {};
  console.log(toRaw(subjects), "subjects");
  for (const subject of Object.values(subjects)) {
    if (!subject.emotion_data) {
      continue;
    }

    for (const emotion of subject.emotion_data) {
      const group_id = subject.group_id;
      emotion_sums[group_id] = emotion_sums[group_id] || {};
      emotion_sums[group_id][emotion.name] =
          emotion_sums[group_id][emotion.name] || {};
      for (const emotion_category of emotion_categories) {
        emotion_sums[group_id][emotion.name][emotion_category] =
            emotion_sums[group_id][emotion.name][emotion_category] ||
            new Array(emotion[emotion_category].length).fill(0);
        for (let i = 0; i < emotion[emotion_category].length; i++) {
          emotion_sums[group_id][emotion.name][emotion_category][i] +=
              emotion[emotion_category][i];
        }
      }
    }
  }
  console.log("emotion_sums", emotion_sums);
  return [emotion_sums];
};

function sumArrays(json) {
  return json[0].map((_, i) => json.reduce((acc, curr) => acc + curr[i], 0));
}

const sumEmotions = (emotions) => {
  const totalSum = sumArrays(emotions);

  // Initialize the sum arrays
  // Create an array of objects with index and value of the top 3 values
  // Return the array of objects
  return totalSum
      .map((value, index) => ({value, index}))
      .sort((a, b) => b.value - a.value)
      .slice(0, 3)
      .map(({value, index}) => ({
        value: Math.round(value * 1000) / 1000,
        index,
      }));
};
const showBanner = ref(false);
const generatePdf = () => {
  showBanner.value = true;
  html2canvas(HtmlReport.value, {
    backgroundColor: "#232323",
    allowTaint: false,
    useCORS: true,
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 522; // A4 width in pt
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Create a custom page size with a fixed width and flexible height
    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: [imgWidth + 40, imgHeight + 140], // Add margins to the dimensions
    });

    const margins = {
      top: 10,
      bottom: 60,
      left: 20,
      width: imgWidth,
    };
    doc.setProperties({
      backgroundColor: "#232323",
    });
    doc.setFillColor("#232323");
    doc.rect(
        0,
        0,
        doc.internal.pageSize.width,
        doc.internal.pageSize.height,
        "F"
    );

    doc.addImage(
        imgData,
        "PNG",
        margins.left,
        margins.top,
        margins.width,
        imgHeight,
        null,
        "FAST"
    );
    showBanner.value = false;
    doc.save("emoscopio.pdf");
  });
};
</script>
<style scoped>
.report > * {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff !important;
  color: #000000 !important;
}

.v-slider-track__tick-label {
  color: #999999 !important;
  font-size: 12px !important;
}
</style>
