<template>
  <!--  <div class="text-h4">{{ group.name }}</div>-->
  <!--  <div class="text-h5">{{ group.description }}</div>-->
  <div v-if="!loadingGroup">
    <v-row>
      <v-col class="justify-end d-flex">
        <v-row></v-row>
        <v-btn
          variant="tonal"
          rounded
          class="button-space"
          @click="
            showDialogSubject = true;
            modeDialogSubject = `new`;
          "
        >
          {{ $t("group.new_subject") }}</v-btn
        >
        <v-btn
          variant="tonal"
          rounded
          class="button-space"
          @click="
            showDialogGroup = true;
            modeDialogGroup = `edit`;
          "
        >
          {{ $t("group.edit_group") }}</v-btn
        >
        <v-btn
          variant="tonal"
          rounded
          class="button-space"
          @click="
            showDialogGroup = true;
            modeDialogGroup = `delete`;
          "
        >
          {{ $t("group.delete_group") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <div class="text-h5">{{ $t("group.group_title") }}</div>
            <div class="text-body-1">{{ resultGroup?.groups_by_pk?.name }}</div>
            <div class="text-h5">{{ $t("group.description_title") }}</div>
            <div class="text-body-1">
              {{ resultGroup?.groups_by_pk?.description }}
            </div>
          </v-col>
        </v-row>

        <v-row v-if="resultSubjectsPerGroup?.subjects?.length > 0">
          <v-col>
            <v-data-table
              v-model:items-per-page="itemsPerPage"
              :headers="headers"
              :items="resultSubjectsPerGroup.subjects.map(item => ({ ...item, raw: item.raw || item }))"
              item-value="name"
              class="elevation-1"
              :footer-props="{ 'fixed-footer': true }"
              :rows-per-page-items="[10, 20, 50]"
            >
              <template v-slot:item.name="{ item }">
                {{ item.raw.name }}
              </template>
              <template v-slot:item.last_name="{ item }">
                {{ item.raw.last_name }}
              </template>
              <template v-slot:item.age="{ item }">
                {{ item.raw.age }}
              </template>
              <template v-slot:item.gender="{ item }">
                {{ item.raw.gender }}
              </template>
              <template v-slot:item.state="{ item }">
                {{ item.raw.state }}
              </template>
              <template v-slot:item.country="{ item }">
                {{ item.raw.country }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  size="small"
                  class="me-2"
                  @click="
                    showDialogSubject = true;
                    modeDialogSubject = `edit`;
                    itemId = item.raw?.id || item.id;
                    refreshDialogSubject++;
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  size="small"
                  @click="
                    showDialogSubject = true;
                    modeDialogSubject = `delete`;
                    itemId = item.raw?.id || item.id;
                    refreshDialogSubject++;
                  "
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-else class="text-body-1">
          <v-col>{{ $t("group.create_subjects_message") }}</v-col>
        </v-row>
        <!--        <v-pagination v-model="page" :length="2"></v-pagination>-->

        <dialog-group
          :key="refreshDialogGroup"
          :visible="showDialogGroup"
          :mode="modeDialogGroup"
          :params="{ _eq: props.groupId }"
          item-name="Grupo"
          :itemId="props.groupId"
          :itemDefaultValue="{
            name: ``,
            description: ``,
          }"
          :getQuery="GET_GROUP_BY_ID"
          :createQuery="CREATE_GROUP"
          :updateQuery="UPDATE_GROUP"
          :deleteQuery="DELETE_GROUP"
          @update:visible="(value) => (showDialogGroup = value)"
          @trigger:refetchCreate="
            router.push(`/project/${route.params.projectId}`)
          "
          @trigger:refetchUpdate="
            refetchGroup({
              id: route.params.groupId,
              fetchPolicy: 'network-only',
            })
          "
          @trigger:refetchDelete="
            router.push(`/project/${route.params.projectId}`)
          "
        >
        </dialog-group>
        <dialog-subject
          :key="refreshDialogSubject"
          :visible="showDialogSubject"
          :mode="modeDialogSubject"
          :params="{ groupId: props.groupId, projectId: props.projectId }"
          :item-name="$t('subject.subject')"
          :itemId="itemId"
          :itemDefaultValue="{
            name: ``,
            last_name: ``,
            gender: ``,
            age: 0,
            country: ``,
            state: ``,
          }"
          :getQuery="GET_SUBJECT_BY_ID"
          :createQuery="CREATE_SUBJECT"
          :updateQuery="UPDATE_SUBJECT"
          :deleteQuery="DELETE_SUBJECT"
          @update:visible="(value) => (showDialogSubject = value)"
          @trigger:refetch-update="
            () => {
              refetchSubjectsPerGroup({
                groupId: route.params.groupId,
                projectId: route.params.projectId,
                fetchPolicy: 'network-only',
              });
              emit('trigger:refetch');
            }
          "
          @trigger:refetchDelete="
            () => {
              refetchSubjectsPerGroup({
                groupId: route.params.groupId,
                projectId: route.params.projectId,
                fetchPolicy: 'network-only',
              });
              emit('trigger:refetch');
            }
          "
          @trigger:refetch="
            (data) => {
              router.push(
                `/project/${route.params.projectId}/subject/${data?.data?.insert_subjects_one?.id}`
              );
              emit('trigger:refetch');
            }
          "
        >
        </dialog-subject>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";

import {
  CREATE_SUBJECT,
  UPDATE_SUBJECT,
  DELETE_SUBJECT,
  GET_SUBJECTS_PER_GROUP,
  GET_SUBJECT_BY_ID,
} from "@/queries/subjects";

import DialogSubject from "@/components/dialogs/SubjectsAdmin.vue";
let itemsPerPage = ref(10);
let itemId = ref(-1);
let showDialogSubject = ref(false);
let modeDialogSubject = ref("new");
let refreshDialogSubject = ref(0);
const { t } = useI18n();
import DialogGroup from "@/components/dialogs/GroupsAdmin.vue";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  GET_GROUP_BY_ID,
  UPDATE_GROUP,
} from "@/queries/group";
import router from "@/router";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
const route = useRoute();

// let itemIdGroup = ref(-1);
let showDialogGroup = ref(false);
let modeDialogGroup = ref("new");
let refreshDialogGroup = ref(0);
const headers = [
  {
    title: t("group.name_header"),
    align: "start",
    key: "name",
    width: "20%",
  },
  { title: t("group.last_name_header"), key: "last_name", width: "30%" },
  { title: t("group.age_header"), key: "age" },
  { title: t("group.gender_header"), key: "gender" },
  { title: t("group.state_header"), key: "state", width: "20%" },
  { title: t("group.country_header"), key: "country", width: "20%" },
  {
    title: t("table.actions"),
    key: "actions",
    width: "10%",
    sortable: false,
  },
];
const props = defineProps({
  groupId: {
    type: Number,
    required: true,
  },
  projectId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["trigger:refetch"]);

// TODO: Make only one request
const { result: resultSubjectsPerGroup, refetch: refetchSubjectsPerGroup } =
  useQuery(GET_SUBJECTS_PER_GROUP, { id: route.params.groupId });

const {
  result: resultGroup,
  refetch: refetchGroup,
  loading: loadingGroup,
} = useQuery(GET_GROUP_BY_ID, { id: props.groupId });

watch(
  () => [route.params.groupId, route.params.projectId],
  () => {
    refetchSubjectsPerGroup({
      id: route.params.groupId,
      fetchPolicy: "network-only",
    });
    refetchGroup({ id: route.params.groupId, fetchPolicy: "network-only" });
  },
  { immediate: true }
);
//
// const handleDeleteGroup = (id) => {
//   console.log("handleDeleteGroup", id);
//   deleteGroup({ id: props.groupId });
// };
//
// const { mutate: deleteGroup, onDone: onDeleteGroupDone } =
//   useMutation(DELETE_GROUP);
//
// onDeleteGroupDone(() => {
//   console.log("onDeleteGroupDone");
//   dialogDeleteGroup.value = false;
//   // user the router to reload the actual page
//
//   // const instance = getCurrentInstance();
//   // instance?.proxy?.$forceUpdate();
// });
//
// onDeleteSubjectDone(() => {
//   console.log("onDeleteSubjectDone");
//   refetchSubjectsPerGroup({ id: props.groupId });
//   dialogDeleteSubject.value = false;
// });
</script>

<style scoped></style>
