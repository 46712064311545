<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useSignInEmailPassword } from "@nhost/vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const router = useRouter();
const email = ref("");
const password = ref("");

const {
  signInEmailPassword,

  isError,
  error,
} = useSignInEmailPassword();

const errorMessage = computed(() => {
  console.log(error);
  let message;
  if (error.value.status === 10) message = t("login.error_invalid_email");
  if (error.value.status === 401) message = t("login.error_wrong_credentials");
  return message;
});
const onLogin = async () => {
  await signInEmailPassword(email, password);
  await router.push("/");
};
</script>
<template>
  <v-container class="flex-grow-1">
    <v-form class="d-flex">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="4">
          <v-row>
            <v-col>
              <div class="text-h5">
                <img
                  src="@/assets/logo-emoscopio.png"
                  width="196"
                  height="51"
                />
              </div>
              <v-text-field
                v-model="email"
                :label="t('login.email_label')"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :label="t('login.password_label')"
                required
                type="password"
              ></v-text-field>
              <v-btn rounded color="success" class="me-4" @click="onLogin">
                {{ t("login.login_button") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <RouterLink
                :to="{ name: 'forgot-password' }"
                style="text-decoration: none; color: inherit"
              >
                <div>{{ t("login.forgot_password_link") }}</div>
              </RouterLink>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      {{isError}}
      <v-snackbar v-model="isError">

        <template v-slot:actions>
          <v-btn color="pink" variant="text" @click="isError = false">
            {{ errorMessage }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-form>
  </v-container>
</template>
