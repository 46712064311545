<template>
  <v-row class="ma-0 pa-0" style="border-bottom: 2px solid rgb(49, 49, 49)">
    <v-col cols="1" class="d-flex justify-center">
      <div class="text-subtitle-2">{{ $t("notes") }}</div>
    </v-col>
    <v-col cols="11" class="ma-0 pa-0">
      <canvas
          style="display: block; background-color: transparent"
          ref="canvas"
          width="1800"
          height="50"
          @mousedown="handleMouseDown"
          @mouseup="handleMouseUp"
          @mousemove="handleMouseMove"
      ></canvas>
    </v-col>
  </v-row>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title> Add Note</v-card-title>
      <v-card-text>
        <v-textarea v-model="notes" label="Note"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="addSquareWithNote">Save</v-btn>
        <v-btn
            @click="
            dialog = false;
            removeLastRegion();
          "
        >Cancel
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const notes = ref(); // create the note var
const dialog = ref(false); // create the dialog var
import {drawTimeline} from "@/helpers";
import {onMounted, ref, watch, watchEffect} from "vue";

const emit = defineEmits(["update"]);


onMounted(() => {
  console.log("Aqui se monto el componente")
  const parent = canvas.value.parentNode;
  canvas.value.width = parent.offsetWidth;
  canvas.value.height = props.height;
  ctx.value = canvas.value.getContext("2d");
  // regionsWatcher();
  drawCanvas();

});

const props = defineProps({
  height: {
    type: Number,
    default: 50,
  },
  seconds: {
    type: Number,
    default: 600,
  },
  marginBottom: {
    type: Number,
    default: 0,
  },
  totalTimeHints: {
    type: Number,
    default: 20,
  },
  regions: {
    type: Array,
    default: () => [],
  },
});

const canvas = ref(null);
const ctx = ref(null);
let regions = ref([]);
let selectingRegion = ref(false);
let startSecond = ref(null);
let resizingRegion = ref(null);
let resizingLeft = ref(false);
let redLineX = ref(null);
const second = ref(null); // create the second ref
regions.value = props.regions

//  print regions values



const handleMouseDown = (event) => {
  let x = event.clientX - canvas.value.offsetLeft;
  second.value = Math.round(x / 3); // assign value to second ref

  if (resizingRegion.value !== null) {
    return;
  }

  for (let i = 0; i < regions.value.length; i++) {
    let leftBorder = regions.value[i].startSecond;
    let rightBorder = regions.value[i].endSecond;

    if (Math.abs(second.value - leftBorder) <= 2) {
      resizingRegion.value = i;
      resizingLeft.value = true;
      canvas.value.style.cursor = "ew-resize"; // change cursor
      return;
    } else if (Math.abs(second.value - rightBorder) <= 2) {
      resizingRegion.value = i;
      resizingLeft.value = false;
      canvas.value.style.cursor = "ew-resize"; // change cursor
      return;
    }
  }

  for (let i = 0; i < regions.value.length; i++) {
    if (
        second.value >= regions.value[i].startSecond &&
        second.value <= regions.value[i].endSecond
    ) {
      console.log(second.value);

      if (
          selectingRegion.value === false && // make the note var true
          confirm("Are you sure you want to delete this region?")
      ) {
        regions.value.splice(i, 1);
        emit("update", regions.value);
        drawCanvas();
        return;
      } else if (selectingRegion.value === false) {
        return;
      }
    }
  }

  if (!selectingRegion.value) {
    selectingRegion.value = true;
    startSecond.value = second.value;
    redLineX.value = x;
    drawRedLine();
  } else {
    selectingRegion.value = false;
    redLineX.value = null;
    let color = colors[Math.floor(Math.random() * colors.length)];

    let newRegion = {
      startSecond: startSecond.value,
      endSecond: second.value,
      text: notes.value,
      color: color,
    };
    let overlappingRegion = regions.value.find((region) => {
      return (
          (newRegion.startSecond >= region.startSecond &&
              newRegion.startSecond <= region.endSecond) ||
          (newRegion.endSecond >= region.startSecond &&
              newRegion.endSecond <= region.endSecond)
      );
    });
    if (overlappingRegion) {
      alert("Cannot create overlapping regions");
      return;
    }
    console.log(newRegion);
    dialog.value = true;
    regions.value.push(newRegion);
    drawCanvas();
  }
};

const handleMouseMove = (event) => {
  let x = event.clientX - canvas.value.offsetLeft;
  second.value = Math.round(x / 3); // assign value to second ref

  if (resizingRegion.value !== null) {
    let region = regions.value[resizingRegion.value];

    if (resizingLeft.value) {
      if (second.value < region.endSecond) {
        region.startSecond = second.value;
        drawCanvas();
      }
    } else {
      if (second.value > region.startSecond) {
        region.endSecond = second.value;
        drawCanvas();
      }
    }
  } else {
    for (let i = 0; i < regions.value.length; i++) {
      let leftBorder = regions.value[i].startSecond;
      let rightBorder = regions.value[i].endSecond;

      if (
          Math.abs(second.value - leftBorder) <= 2 ||
          Math.abs(second.value - rightBorder) <= 2
      ) {
        canvas.value.style.cursor = "ew-resize"; // change cursor
        return;
      }
    }
    canvas.value.style.cursor = "default"; // reset cursor
  }
};

const handleMouseUp = () => {
  canvas.value.style.cursor = "default"; // reset cursor
  if (resizingRegion.value !== null) {
    emit("update", regions.value);
  }

  resizingRegion.value = null;
  // emit("update", regions.value);
};

function drawCanvas() {
  ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
  console.log("Draw canvas", regions.value)
  // ctx.value.fillText("Timeline", canvas.value.width / 2, 20);
  drawTimeline(
      ctx,
      props.seconds,
      props.marginBottom,
      canvas.value.width,
      canvas.value.height,
      props.totalTimeHints
  );

  for (let i = 0; i < regions.value.length; i++) {
    let region = regions.value[i];
    console.log("region postiion", region)
    // get a random color from the array
    let color = colors[Math.floor(Math.random() * colors.length)];

    // if the region has no color assign a random color
    if (!region.color) {
      region.color = color;
    }

    ctx.value.fillStyle = region.color;
    ctx.value.fillRect(
        region.startSecond * 3,
        0,
        (region.endSecond - region.startSecond + 1) * 3,
        canvas.value.height
    );
    ctx.value.fillStyle = region.color;
  }

  if (selectingRegion.value && redLineX.value !== null) {
    drawRedLine();
  }
}

// Create and array of 10 pastel colors in hexadecimal with 5 alpha

const colors = [
  "#FF000077",
  "#FF7F0077",
  "#FFFF0077",
  "#00FF0077",
  "#0000FF77",
  "#4B008277",
  "#9400D377",
  "#FF00FF77",
  "#FF000077",
  "#FF7F0077",
  "#FFFF0077",
  "#00FF0077",
  "#0000FF77",
  "#4B008277",
  "#9400D377",
  "#FF00FF77",
];

function drawRedLine() {
  ctx.value.beginPath();
  ctx.value.moveTo(redLineX, 0);
  ctx.value.lineTo(redLineX, canvas.value.height);
  ctx.value.strokeStyle = "red";
  ctx.value.lineWidth = 1;
  ctx.value.stroke();
  ctx.value.strokeStyle = "black"; // Reset the stroke color
}

watch(
    () => props.regions,
    (newRegions, oldRegions) => {
      console.log("-------------watch: regions changed", newRegions);
      if (newRegions === null) {
        console.log("regions set to null");
        regions.value = [];
      } else {
        regions.value = newRegions.map((region) => ({...region}));
      }
      drawCanvas();
    },
    {deep: true} // Add deep watch
);

// existing code
function removeLastRegion() {
  regions.value.pop(); // remove last region from regions array
  drawCanvas(); // redraw canvas without last region
}

// existing code
// Define the addSquareWithNote function
function addSquareWithNote() {
  // Get the last region element and update the note value
  regions.value[regions.value.length - 1].text = notes.value;
  console.log(regions.value);
  emit("update", regions.value);
  notes.value = "";
  dialog.value = false;

  drawCanvas();
}
</script>
