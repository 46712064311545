<template>
  <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script setup>
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "vue-chartjs";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { EMOTIONS_COLORS } from "@/constants";
import { computed } from "vue";

const props = defineProps({
  id: String,
  options: Object,
  data: Object,
  labels: Array,
});

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

let chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        backgroundColor: EMOTIONS_COLORS,
      },
    ],
  };
});

const defaulOptions = {
  layout: {
    padding: {
      left: 20,
      right: 40,
      top: 20,
      bottom: 10,
    },
  },
  height: 400,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "#fff",
      anchor: "end",
      align: "end",
      offset: 5,
      clamp: true,
      formatter: (value) => `${value.toFixed(2)}`,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        color: "white",
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        color: "white",
      },

      position: "right",
    },
  },
  responsive: true,
};
let chartOptions = computed(() => {
  return {...defaulOptions, ...props.options}
});
</script>
