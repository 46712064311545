import { gql } from "@apollo/client/core";

export const GET_SUBJECT_BY_ID = gql`
  query GetSubject($id: Int!) {
    subjects_by_pk(id: $id) {
      country
      gender
      last_name
      age
      name
      state
    }
  }
`;

export const GET_SUBJECTS_BY_IDS = gql`
  query GetSubjectByIds($id: [Int!]) {
    subjects(where: { id: { _in: $id } }) {
      country
      gender
      group_id
      last_name
      age
      name
      state
      id
      video_url
      face_emotions
      voice_emotions
    }
  }
`;
export const GET_SUBJECTS_PER_GROUP = gql`
  query GetSubjectsPerGroup($id: Int!) {
    subjects(where: { group_id: { _eq: $id } }) {
      country
      gender
      group_id
      last_name
      age
      name
      state
      video_url
      id
    }
  }
`;
export const GET_SUBJECTS_PER_PROJECT = gql`
  query GetSubjectsPerProject($id: Int!) {
    projects(where: { id: { _eq: $id } }) {
      title
      description
      groups {
        id
        description
        name
        subjects(order_by: { created_at: desc }) {
          id
          country
          gender
          last_name
          name
          thumbnail
          face_emotions
          voice_emotions
          calibration
          notes
        }
      }
    }
  }
`;

export const UPDATE_SUBJECT = gql`
  mutation UpdateSubject(
    $id: Int!
    $age: Int
    $country: String
    $gender: String
    $last_name: String
    $name: String
    $state: String
  ) {
    update_subjects(
      where: { id: { _eq: $id } }
      _set: {
        age: $age
        country: $country
        gender: $gender
        last_name: $last_name
        name: $name
        state: $state
      }
    ) {
      affected_rows
    }
  }
`;

export const CREATE_SUBJECT = gql`
  mutation CreateSubject(
    $name: String!
    $last_name: String!
    $gender: String!
    $age: Int!
    $state: String
    $country: String
    $groupId: Int!
    $projectId: Int!
    $videoUrl: String
  ) {
    insert_subjects_one(
      object: {
        name: $name
        last_name: $last_name
        gender: $gender
        age: $age
        state: $state
        country: $country
        group_id: $groupId
        project_id: $projectId
        video_url: $videoUrl
      }
    ) {
      id
    }
  }
`;

export const UPDATE_SUBJECT_NOTES = gql`
  mutation UpdateSubject($id: Int!, $notes: json!) {
    update_subjects(where: { id: { _eq: $id } }, _set: { notes: $notes }) {
      returning {
        id
      }
    }
  }
`;
export const UPDATE_SUBJECT_VIDEO_URL = gql`
  mutation UpdateSubject(
    $id: Int!
    $source_video_url: String!
    $video_ext: String!
    $video_url: String!
  ) {
    update_subjects(
      where: { id: { _eq: $id } }
      _set: {
        source_video_url: $source_video_url
        video_ext: $video_ext
        video_url: $video_url
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const SUBSCRIBE_TO_SUBJECTS = gql`
  subscription Subject($id: Int!) {
    subjects(where: { id: { _eq: $id } }) {
      id
      name
      last_name
      gender
      state
      country
      video_url
      source_video_url
      voice_emotions
      face_emotions
      face_position
      progress_processing
      is_published
      calibration
      notes
      subjects_groups {
        id
        name
        description
        projects {
          id
          stimulus_url
          type
        }
      }
    }
  }
`;
export const REMOVE_SUBJECT_VIDEO = gql`
  mutation UpdateSubject($id: Int!) {
    update_subjects(
      where: { id: { _eq: $id } }
      _set: {
        source_video_url: ""
        video_ext: ""
        video_url: ""
        voice_emotions: ""
        face_emotions: ""
        thumbnail: ""
        progress_processing: 0
        face_position: ""
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export const UPDATE_SUBJECT_SHARE_STATUS = gql`
  mutation UpdateSubjectShareStatus($id: Int!, $is_published: Boolean!) {
    update_subjects(
      where: { id: { _eq: $id } }
      _set: { is_published: $is_published }
    ) {
      returning {
        id
      }
    }
  }
`;
export const DELETE_SUBJECT = gql`
  mutation DeleteSubject($id: Int!) {
    delete_subjects_by_pk(id: $id) {
      id
    }
  }
`;

// export const GET_SUBJECTS_CALIBRATION = gql`
//   query GetSubjectsCalibration($id: Int!) {
//     subjects(where: { id: $id }) {
//       calibration
//       id
//     }
//   }
// `;

export const GET_SUBJECTS_CALIBRATION = gql`
  query GetSubjectsCalibration($id: Int!) {
    subjects_by_pk(id: $id) {
      name
      last_name
      calibration
    }
  }
`;

export const UPDATE_SUBJECT_CALIBRATION = gql`
  mutation UpdateSubjectCalibration($id: Int!, $calibration: json = "") {
    update_subjects_by_pk(
      pk_columns: { id: $id }
      _set: { calibration: $calibration }
    ) {
      id
    }
  }
`;
