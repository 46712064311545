import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProjectView from "@/views/ProjectView.vue";
import LoginView from "@/views/LoginView.vue";
import PrintView from "@/views/PrintView.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import CalibrationView from "@/views/CalibrationView.vue";
import UsersView from "@/views/admin/UsersView.vue";
import UserProfileView from "@/views/UserProfileView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      meta: {
        protected: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: {
        protected: true,
      },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: ChangePassword,
      meta: {
        protected: true,
      },
    },
    {
      path: "/report/:projectId",
      name: "report",
      component: ProjectView,
      meta: {
        protected: true,
      },
      props: paramsToPropsCaster({ projectId: Number }),
    },
    {
      path: "/project/:projectId",
      name: "project",
      component: ProjectView,
      meta: {
        protected: true,
      },
      props: paramsToPropsCaster({ projectId: Number }),
    },
    {
      path: "/project/:projectId/group/:groupId",
      name: "project-group",
      component: ProjectView,
      props: paramsToPropsCaster({ groupId: Number, projectId: Number }),
      meta: {
        protected: true,
      },
    },
    {
      path: "/project/:projectId/group/:groupId/subject/:subjectId",
      name: "project-subject",
      component: ProjectView,
      props: paramsToPropsCaster({
        subjectId: Number,
        groupId: Number,
        projectId: Number,
      }),
      meta: {
        protected: true,
      },
    },
    {
      path: "/project/:projectId/subject/:subjectId",
      name: "project-subject",
      component: ProjectView,
      props: paramsToPropsCaster({
        subjectId: Number,
        projectId: Number,
      }),
      meta: {
        protected: true,
      },
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/print",
      name: "print",
      component: PrintView,
      meta: {
        protected: true,
      },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/calibration/:subjectId",
      name: "calibration",
      props: paramsToPropsCaster({
        subjectId: Number,
      }),
      component: CalibrationView,
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/profile/:subjectId",
      name: "user-profile",
      props: paramsToPropsCaster({
        subjectId: Number,
      }),
      component: UserProfileView,
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/admin/users",
      name: "users",

      component: UsersView,
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import("../views/AboutView.vue"),
    },
  ],
});

function paramsToPropsCaster(mapping) {
  return function (route) {
    let nameType = Object.entries(mapping); // [[param1, Number], [param2, String]]
    let nameRouteParam = nameType.map(([name, fn]) => [
      name,
      fn(route.params[name]),
    ]); // [[param1, 1], [param2, "hello"]]
    let props = Object.fromEntries(nameRouteParam); // {param1: 1, param2: "hello"}
    return props;
  };
}

export default router;
