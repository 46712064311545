<template>
  <div v-if="props?.items?.projects[0]?.groups">
    <v-card style="overflow: auto; height: 81vh">
      <v-container>
        <v-row dense>
          <v-col
            v-for="(itemProject, indexProject) in props.items.projects[0]
              .groups"
            :key="indexProject.id"
            cols="12"
          >
            <v-row>
              <v-col>
                <v-card
                  theme="dark"
                  @click="onClickCard"
                  :data-user-id="itemProject.id"
                  :data-user-type="'group'"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="text-over-flow">
                      <v-card-title class="capitalize text-body-1">
                        {{ itemProject.name }}
                      </v-card-title>
                      <v-card-subtitle class="capitalize"
                        >{{ itemProject.description }}
                      </v-card-subtitle>
                    </div>
                    <v-avatar class="ma-3" size="90" rounded="0"></v-avatar>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col
                v-for="(item, index) in itemProject.subjects"
                :key="index"
                cols="12"
              >
                <div v-if="calibrationScore(item?.calibration)">
                  {{ $t("calibration_status") }}
                </div>

                <v-card
                  theme="dark"
                  @click="onClickCard"
                  :data-user-id="item.id"
                  :data-user-type="'subject'"
                >
                  <div class="d-flex flex-no-wrap">
                    <v-avatar
                      style="background-color: #ffffff"
                      size="90"
                      rounded="0"
                    >
                      <v-img cover :src="getImage(item?.thumbnail)"></v-img>
                    </v-avatar>
                    <div class="text-over-flow">
                      <v-card-title class="capitalize text-body-1">
                        {{ item.name }} {{ item.last_name }}
                      </v-card-title>
                      <v-card-subtitle class="capitalize"
                        >{{ item.gender }}-{{ item.country }}
                      </v-card-subtitle>
                      <v-card-subtitle class="capitalize"
                        >{{ item.age }}
                      </v-card-subtitle>
                      <!--                <v-card-subtitle>Grupo 1</v-card-subtitle>-->
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script setup>
import {calibrationScore} from "@/helpers";

const getImage = (item) => {
  return item;
};

const emit = defineEmits(["clickCard"]);

const onClickCard = (item) => {
  console.log("onclickcard", item.currentTarget.getAttribute("data-user-id"));

  emit("clickCard", {
    id: item.currentTarget.getAttribute("data-user-id"),
    type: item.currentTarget.getAttribute("data-user-type"),
  });
};

const props = defineProps({
  items: {
    type: Object || undefined,

    default: () => {},
  },
});
</script>
