<template>
  <div></div>
</template>
<script setup>
import { watch } from "vue";
import { useSubscription } from "@vue/apollo-composable";
import { SUBSCRIBE_TO_SUBJECTS } from "@/queries/subjects";

const emit = defineEmits(["update:subject"]);

const props = defineProps({
  id: { type: Number },
});
const { result: resultSubscription } = useSubscription(SUBSCRIBE_TO_SUBJECTS, {
  id: props.id,
});

watch(
  () => resultSubscription.value,
  (newValue) => {
    emit("update:subject", newValue);
  }
);
</script>
