import { gql } from "@apollo/client/core";

export const GET_GROUP_BY_ID = gql`
  query GetGroupByID($id: Int!) {
    groups_by_pk(id: $id) {
      description
      name
      id
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup(
    $name: String!
    $description: String!
    $projectId: Int!
  ) {
    insert_groups_one(
      object: { name: $name, description: $description, project_id: $projectId }
    ) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup(
    $_eq: Int
    $description: String = ""
    $name: String = ""
  ) {
    update_groups(
      where: { id: { _eq: $_eq } }
      _set: { name: $name, description: $description }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_GROUP = gql`
  mutation DeleteGroup($id: Int!) {
    delete_groups_by_pk(id: $id) {
      id
    }
  }
`;
